



































































































































import router from "@/router";
import { ROUTE_NAMES } from "@/router/routes";
import {
  accountingBalanceSheetsStore,
  accountingPeriodsStore,
  accountingsStore,
  productsStore,
  partnersStore,
  tasksStore,
  transactionsStore,
  usersStore,
} from "@/store";
import { FilterKeys } from "@/utils";
import {
  BalanceCategories,
  getTotalBalancePartner,
  getBalancePartners,
  LedgerAccountEnum,
  Partner,
  PartnerTypeEnum,
  TaxRegime,
} from "@edmp/api";
import {
  computed,
  ComputedRef,
  defineComponent,
  onBeforeMount,
  Ref,
  ref,
  watch,
} from "@vue/composition-api";
import moment from "moment";
import PartnersModal from "./modals/PartnerModal.vue";
import PartnersInvitationModal from "./modals/PartnersInvitationModal.vue";

export default defineComponent({
  name: "Partners",
  components: {
    PartnersModal,
    PartnersInvitationModal,
  },
  props: { productId: { type: String } },

  setup(props) {
    const isLoading = ref(false);
    const partnersList: ComputedRef<Partner[]> = computed(
      () => partnersStore.partners
    );
    const isTaxRegimeIs = computed(() => {
      return (
        accountingPeriodsStore.currentAccountingPeriod?.taxRegime ===
        TaxRegime.IS_2065
      );
    });
    const isTaxRegimeLmnp = computed(() => {
      return (
        accountingPeriodsStore.currentAccountingPeriod?.taxRegime ===
        TaxRegime.LMNP_2031
      );
    });
    const selectedPartner = ref(undefined);
    const openModal: Ref<boolean> = ref(false);
    const openPartnersInvitationModal: Ref<boolean> = ref(false);
    const totalPP: Ref<number> = ref(0);
    const totalUF: Ref<number> = ref(0);
    const totalNP: Ref<number> = ref(0);
    const totalPF: Ref<number> = ref(0);

    const todayDate = moment().format("DD/MM/YYYY");

    const partnerIncomeDetails = (partnerId) => {
      transactionsStore.resetFilter();
      // transactionsStore.addFilter({
      //   code: FilterKeys.TYPES,
      //   filterParams: {
      //     types: [TransactionImportType.EXPENSE_REPORT],
      //     label: "Note de frais",
      //   },
      // });

      transactionsStore.addFilter({
        code: FilterKeys.CATEGORIES,
        filterParams: {
          label: "Compte Associé",
          categories: [LedgerAccountEnum.N455000, LedgerAccountEnum.N455010],
        },
      });
      transactionsStore.addFilter({
        code: FilterKeys.PARTNERS,
        filterParams: {
          ids: [partnerId],
          label: "Associé : " + partnersStore.getPartnerName(partnerId),
        },
      });
      router.push({ name: ROUTE_NAMES.Transactions });
    };

    const partnersHeaders = [
      { text: "Nom", value: "lastName" },
      { text: "Prénom", value: "firstName" },
      { text: "Date De Naissance", value: "birthDate" },
      { text: "Pleine Propriété", value: "partsPP" },
      { text: "Usufruit", value: "partsUF" },
      { text: "Nue Propriété", value: "partsNP" },
      { text: "Fiscales", value: "partsPF" },
      { text: "Accès Ownily", value: "access" },
      { text: "Modifier", value: "update", sortable: false, align: "center" },
    ];

    async function refreshPartners() {
      try {
        await partnersStore.fetchPartners(productsStore.currentId);
        totalPP.value = partnersList.value.reduce(
          (acc: number, item) => acc + item.partsPP,
          0
        );
        totalNP.value = partnersList.value.reduce(
          (acc: number, item) => acc + item.partsNP,
          0
        );
        totalUF.value = partnersList.value.reduce(
          (acc: number, item) => acc + item.partsUF,
          0
        );
        totalPF.value = partnersList.value.reduce(
          (acc: number, item) => acc + item.partsPP + item.partsUF,
          0
        );
      } catch (error) {
        console.error(error);
      }
    }

    function closeModal() {
      openModal.value = false;
      selectedPartner.value = undefined;
    }

    function validatePartnerModal() {
      closeModal();
      tasksStore.fetchTaskGroups({});
      refreshPartners();
    }

    function selectPartner(item) {
      selectedPartner.value = item;
      openModal.value = true;
    }

    const balancePartners: Ref<BalanceCategories["categories"]> = ref([]);
    const getBalance = computed(() => (partnerId) => {
      return getTotalBalancePartner(balancePartners.value, partnerId);
    });
    // Init
    async function init() {
      isLoading.value = true;
      if (props.productId) {
        await productsStore.switchProduct({ id: props.productId });
      }
      await refreshPartners();
      if (accountingPeriodsStore.currentId) {
        if (
          accountingPeriodsStore.currentId !==
          accountingPeriodsStore.lastAccountingPeriodNotClosed.id
        ) {
          const balanceCategories = await accountingsStore.getBalanceCategories(
            accountingPeriodsStore.lastAccountingPeriodNotClosed.id
          );
          balancePartners.value = getBalancePartners(balanceCategories);
        } else {
          const balanceCategories = await accountingsStore.getBalanceCategories(
            accountingPeriodsStore.currentId
          );
          balancePartners.value = getBalancePartners(balanceCategories);
        }
      }
      isLoading.value = false;
    }

    watch(
      () => [
        accountingPeriodsStore.currentId,
        productsStore.currentProduct,
        accountingBalanceSheetsStore.accountingBalanceSheets,
      ],
      () => init(),
      { deep: true }
    );

    onBeforeMount(() => init());

    return {
      isLoading,
      isTaxRegimeIs,
      isTaxRegimeLmnp,
      connectedUser: computed(() => usersStore.loggedInUser),
      openModal,
      closeModal,
      refreshPartners,
      partnersHeaders,
      partnersList,
      selectPartner,
      openPartnersInvitationModal,
      totalPP,
      totalUF,
      totalNP,
      totalPF,
      selectedPartner,
      validatePartnerModal,
      PartnerTypeEnum,
      getBalance,
      todayDate,
      partnerIncomeDetails,
      balancePartners,
    };
  },
});
