


















































































































































import { useEmail } from "@/composables";
import { VForm } from "@/models";
import { usersService } from "@/services";
import { coreStore, tasksStore, usersStore } from "@/store";
import { FeedbackTypeEnum } from "@/store/modules/Core.store";
import { User } from "@edmp/api";
import {
  defineComponent,
  onBeforeMount,
  ref,
  Ref,
  watch,
} from "@vue/composition-api";

export default defineComponent({
  name: "UserConnexion",
  setup(props, context) {
    const user: Ref<User | undefined> = ref();

    // Gestion de l'edition / annulation
    const isEditingMail: Ref<boolean> = ref(false);

    async function validate(e: Event): Promise<void> {
      e.preventDefault();

      if ((context.refs.form as VForm).validate()) {
        try {
          if (user.value?.email === user.value?.newEmail) {
            coreStore.displayFeedback({
              type: FeedbackTypeEnum.INFO,
              timeout: 10000,
              message:
                "Veuillez modifier votre email avant de valider ou cliquez sur annuler.",
            });
            return;
          }

          await usersService
            .updateUser({
              id: user.value?.id,
              newEmail: user.value?.newEmail,
              emailStatus: "pending",
            } as User)
            .then(() => {
              usersStore.fetchLoggedInUser();
              context.emit("validate");
              isEditingMail.value = false;
            });
          coreStore.displayFeedback({
            timeout: 15000,
            type: FeedbackTypeEnum.INFO,
            message:
              "Une vérification vient d'être envoyée sur la nouvelle adresse email saisie : validez-la pour activer le nouvel identifiant.",
          });
        } catch (err) {
          coreStore.displayFeedback({
            type: FeedbackTypeEnum.ERROR,
            message:
              "Une erreur est survenue lors de la mise à jour de votre adresse email.\n" +
              "L'adresse email est peut-être déjà associée à un compte. ",
          });
        }
        await tasksStore.fetchTaskGroups({});
        await usersStore.fetchLoggedInUser();
      }
    }

    const { validEmailRule, getEmailRule } = useEmail();

    function cancelUpdate() {
      isEditingMail.value = false;
    }

    // Call backend to reset email status to pending in order to resend a mail
    const resetEmailStatus = () => {
      usersStore.fetchLoggedInUser().then(async () => {
        if (user.value?.newEmail) {
          usersService
            .updateUser({ id: "me", newEmail: user.value?.newEmail } as User)
            .then(() =>
              coreStore.displayFeedback({
                type: FeedbackTypeEnum.INFO,
                timeout: 13000,
                message:
                  "Une vérification vient d'être envoyée sur la nouvelle adresse email saisie : validez-la pour activer le nouvel identifiant.",
              })
            );
        }
      });
    };

    const cancelEmailStatus = async () => {
      // Revient à l'état initial en supprimant le nouvel email et en restituant l'état
      // DOit on accepter de passer à confirm ...
      usersService
        .updateUser({
          id: "me",
          emailStatus: "confirmed",
          newEmail: null,
        } as User)
        .then(async () => {
          await usersStore.fetchLoggedInUser();
          await tasksStore.fetchTaskGroups({});
        });
    };

    const init = () => {
      user.value = { ...usersStore.loggedInUser };
    };

    watch(
      () => usersStore.loggedInUser,
      () => init(),
      { deep: true }
    );

    onBeforeMount(() => init());

    return {
      user,
      isEditingMail,
      validEmailRule,
      getEmailRule,
      validate,
      cancelUpdate,
      resetEmailStatus,
      cancelEmailStatus,
    };
  },
});
