











































































import { tasksStore } from "@/store";
import {
  getReferredIdByTypeWithReferences,
  TaskTypeReference,
} from "@edmp/api";
import { computed, defineComponent } from "@vue/composition-api";
import { useAccountingPeriod } from "../../accounting-period/AccountingPeriod.usable";

export default defineComponent({
  name: "Events",
  setup() {
    const taskGroupsWithDueDate = computed(() => {
      return tasksStore.notCompletedTaskGroupWithDueDate;
    });

    const { getAccountingPeriodTextById } = useAccountingPeriod();

    return {
      events: taskGroupsWithDueDate,
      getAccountingPeriodTextById,
      getReferredIdByTypeWithReferences,
      TaskTypeReference,
    };
  },
});
