





























































































































































































































































































import { VForm } from "@/models";
import { productsStore, coreStore, tasksStore, activitiesStore } from "@/store";
import { FeedbackTypeEnum } from "@/store/modules/Core.store";
import { onlyNumber } from "@/utils";
import { ActivitiesModel, ProductsModel } from "@edmp/api";
import {
  computed,
  ComputedRef,
  defineComponent,
  onBeforeMount,
  PropType,
  ref,
  Ref,
  watch,
} from "@vue/composition-api";
import { cloneDeep } from "lodash";

export default defineComponent({
  name: "MyBusinessGeneralInfo",
  props: {
    loading: { type: Boolean, default: false },
    productId: { type: String },
    actions: { type: String as PropType<"create" | "update"> },
  },

  setup(props, context) {
    const notChecked: ComputedRef<Boolean> = computed(() => {
      return true;
    });
    /**
     * ProductData
     */
    const isLoading: Ref<boolean> = ref(props.loading);
    const activity: Ref<
      | (ActivitiesModel.Activity<ActivitiesModel.ActivityTypes.OPERATOR> & {
          name: string;
        })
      | undefined
    > = ref();
    // Infos générales
    const isEditing: Ref<boolean> = ref(false);
    // Infos générales - Autocompletion par recherche
    const searchProducts: Ref<boolean> = ref(false);

    const rules = computed(() => ({
      cgaZip: () =>
        activity.value?.hasCga
          ? (!!activity.value?.cga?.address?.zip &&
              onlyNumber(activity.value.cga.address.zip)) ||
            "Le format du code postal de votre CGA est incorrect"
          : true || "",
      cgaCity: () =>
        activity.value?.hasCga
          ? !!activity.value.cga?.address?.city ||
            "Renseignez la ville associée à votre CGA"
          : true || "",
      cgaStreet: () =>
        activity.value?.hasCga
          ? !!activity.value.cga?.address?.street ||
            "Renseignez la rue associée à votre CGA"
          : true || "",
      cgaAgreementNumber: () =>
        activity.value?.hasCga
          ? (!!activity.value.cga?.agreementNumber &&
              onlyNumber(activity.value.cga.agreementNumber) &&
              activity.value.cga.agreementNumber.toString().length === 6) ||
            "Le format du numéro d'agrément de votre CGA est incorrect"
          : true || "",
      cgaName: () =>
        activity.value?.hasCga
          ? !!activity.value?.cga?.name || "Renseignez le nom de votre CGA"
          : true || "",

      name: () =>
        activity.value?.hasCga
          ? !!activity.value?.cga?.name || "Renseignez le nom de votre activité"
          : true || "",
      zip: () =>
        (!!activity.value?.activityAddress?.zip &&
          onlyNumber(activity.value.activityAddress.zip)) ||
        "Le format du code postal de votre activité est incorrect",
      city: () =>
        !!activity.value?.activityAddress?.city ||
        "Renseignez la ville associée à votre activité",
      street: () =>
        !!activity.value?.activityAddress?.street ||
        "Renseignez la rue associée à votre activité",
      siret: () =>
        (!!activity.value?.siret &&
          onlyNumber(activity.value.siret) &&
          activity.value?.siret.length === 14) ||
        "Le format de votre SIRET est incorrect",
    }));

    /**
     * Actions
     */
    async function updateProduct() {
      isLoading.value = true;

      // Validate
      let isValidRules = true;
      for (const rule in rules.value) {
        const isValidRule = rules.value[rule]();
        if (isValidRule !== true) {
          isValidRules = false;
          coreStore.displayFeedback({
            type: FeedbackTypeEnum.ERROR,
            message: isValidRule,
          });
        }
      }

      let activityUpdate;
      let name;
      if (activity.value) {
        const {
          cga,
          hasCga,
          name: ProductName,
          ...activityUpdateTemp
        } = activity.value;
        activityUpdate = { hasCga, ...activityUpdateTemp };
        name = ProductName;
        if (hasCga && cga) {
          activityUpdate.cga = cga;
        }
      }

      // Process
      if (
        isValidRules &&
        activityUpdate &&
        name &&
        (context.refs.form as VForm).validate()
      ) {
        try {
          if (activityUpdate.id) {
            await productsStore.updateProduct({
              id: activityUpdate.productId,
              name,
            });
            await activitiesStore.updateActivity(activityUpdate);
          } else {
            await productsStore.createProduct({
              type: ProductsModel.ProductTypes.LMNP,
              name,
              activity: activityUpdate,
              dedicatedBankAccount: false,
              accountingPeriods: [],
            });
          }
          await tasksStore.fetchTaskGroups({});
          coreStore.displayFeedback({
            message: "La mise à jour de votre LMNP a bien été prise en compte",
          });
          context.emit("validate", true);
          isEditing.value = false;
        } catch (err) {
          coreStore.displayFeedback({
            type: FeedbackTypeEnum.ERROR,
            message:
              "Une erreur est survenue lors de la mise à jour de votre LMNP",
          });

          if (productsStore.currentProduct && activity.value) {
            activity.value = Object.assign(
              productsStore.currentProduct.activity
            );
          }
          isLoading.value = false;
        }
      }

      isLoading.value = false;
    }

    function cancelUpdate() {
      const currentProduct = productsStore.currentProduct;
      activity.value = Object.assign(
        {
          activityAddress: { street: "", city: "", zip: "" },
          hasCga: false,
          cga: {
            name: "",
            agreementNumber: undefined,
            address: { street: "", city: "", zip: "" },
          },
        },
        {
          ...currentProduct?.activity,
          name: currentProduct?.name,
        } as ActivitiesModel.Activity<ActivitiesModel.ActivityTypes.OPERATOR> & {
          name: string;
        }
      );

      isEditing.value = false;
    }

    // Flips CGA button according to user action before cancel or confirm action
    function toggleCgaBtn(boolean: boolean) {
      if (isEditing.value && activity.value) {
        activity.value.hasCga = boolean;
      }

      if (isEditing.value && boolean === false) {
        if (activity.value) {
          activity.value.cga = {
            name: "",
            agreementNumber: undefined,
            address: { street: "", city: "", zip: "" },
          };
        }
      }
    }

    /**
     * Init
     */
    async function init() {
      isLoading.value = true;
      if (!props.actions || props.actions === "update") {
        if (props.productId) {
          await productsStore.switchProduct({ id: props.productId });
        }
        activity.value = Object.assign(
          {
            activityAddress: { street: "", city: "", zip: "" },
            hasCga: false,
            cga: {
              name: "",
              agreementNumber: undefined,
              address: { street: "", city: "", zip: "" },
            },
          },
          {
            ...cloneDeep(productsStore.currentProduct?.activity ?? {}),
            name: productsStore.currentProduct?.name,
          } as ActivitiesModel.Activity<ActivitiesModel.ActivityTypes.OPERATOR> & {
            name: string;
          }
        );
      } else {
        activity.value =
          productsStore.currentProduct?.status ===
          ProductsModel.ProductStatus.pending
            ? Object.assign(
                {
                  name: "",
                  activityAddress: { street: "", city: "", zip: "" },
                  hasCga: false,
                  cga: {
                    name: "",
                    agreementNumber: undefined,
                    address: { street: "", city: "", zip: "" },
                  },
                },
                {
                  ...JSON.parse(
                    JSON.stringify(productsStore.currentProduct?.activity)
                  ),
                }
              )
            : {
                name: "",
                siret: "",
                ape: "",
                activityAddress: { street: "", zip: "", city: "" },
                hasCga: false,
                cga: {
                  name: "",
                  agreementNumber: undefined,
                  address: { street: "", city: "", zip: "" },
                },
              };
        if (!activity.value?.id) {
          isEditing.value = true;
        }
      }

      isLoading.value = false;
    }

    watch(
      () => [productsStore.currentProduct, props.actions],
      () => init(),
      { deep: true }
    );

    onBeforeMount(() => init());
    return {
      activity,
      isLoading,
      isEditing,
      searchProducts,
      onlyNumber,
      rules,
      cancelUpdate,
      updateProduct,
      notChecked,
      toggleCgaBtn,
    };
  },
});
