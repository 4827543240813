


























































































import DatePicker from "@/components/atom/DatePicker.vue";
import { VForm } from "@/models";
import router from "@/router";
import { ROUTE_NAMES } from "@/router/routes";
import { productsStore, assembliesStore, tasksStore } from "@/store";
import { Address, ProductsModel, GeneralAssemblyEvent } from "@edmp/api";
import { computed, defineComponent, PropType, ref } from "@vue/composition-api";
import moment from "moment";

export default defineComponent({
  name: "EventsGeneralAssemblyOrdinaryModal",
  components: {
    DatePicker,
  },
  props: {
    type: {
      type: String as PropType<GeneralAssemblyEvent["type"]>,
      required: true,
    },
    pattern: {
      type: String as PropType<GeneralAssemblyEvent["pattern"]>,
      required: true,
    },
    productId: {
      type: String,
      required: true,
    },
    accountingPeriodId: {
      type: String,
      required: true,
    },
    hour: {
      type: String,
      required: false,
    },
    address: {
      type: Object as PropType<Address>,
      required: true,
    },
    event: {
      type: Object as PropType<GeneralAssemblyEvent>,
    },
  },
  setup(props, context) {
    const product = computed(() =>
      props.productId
        ? (productsStore.getProduct({
            id: props.productId,
          }) as ProductsModel.Product)
        : (productsStore.currentProduct as ProductsModel.Product)
    );
    const today = ref(moment().format("YYYY-MM-DD"));
    const convocationMedium = ref<
      NonNullable<GeneralAssemblyEvent["convocationMedium"]>[]
    >(["courrier simple", "recommandé avec AR"]);
    const generalAssemblyDate = ref(
      props.event
        ? moment(props.event.startAt).format("YYYY-MM-DD") //format(new Date(props.event.startAt as string), "yyyy-MM-dd")
        : today.value
    );
    const generalAssemblyHour = ref(
      props.event
        ? moment(props.event.startAt).format("HH:mm") //format(new Date(props.event.startAt as string), "HH:mm")
        : props.hour
        ? moment(props.hour).format("HH:mm")
        : "" //props.hour
    );
    const generalAssemblyAddress = ref(
      props.event ? props.event.address : props.address
    );
    const generalAssemblyConvocationMethod = ref(
      props.event ? props.event.convocationMedium : convocationMedium.value[0]
    );

    function validateFormat(generalAssemblyHour: string) {
      return moment(generalAssemblyHour, "HH:mm", true).isValid();
    }

    async function create(parsedDate: string): Promise<GeneralAssemblyEvent> {
      try {
        const generalAssemblyEvent =
          await assembliesStore.createGeneralAssemblyEvent({
            productId: product.value.id,
            accountingPeriodId: props.accountingPeriodId,
            type: props.type,
            pattern: props.pattern,
            startAt: parsedDate,
            address: generalAssemblyAddress.value,
            convocationMedium: generalAssemblyConvocationMethod.value,
          });
        await tasksStore.fetchTaskGroups({});
        return generalAssemblyEvent;
      } catch (error) {
        console.error(error);
        throw error;
      }
    }

    async function update(parsedDate: string): Promise<void> {
      try {
        if (props.event?.id) {
          const generalAssemblyEvent = {
            id: props.event.id,
            startAt: parsedDate,
            address: generalAssemblyAddress.value,
            convocationMedium: generalAssemblyConvocationMethod.value,
          };
          await assembliesStore.update(generalAssemblyEvent);
        }
      } catch (error) {
        console.error(error);
        throw error;
      }
    }

    async function validate() {
      if ((context.refs.form as VForm).validate()) {
        const parsedDate = moment(
          `${generalAssemblyDate.value} ${generalAssemblyHour.value}`,
          "YYYY-MM-DD HH:mm"
        ).toISOString();

        if (props.event) {
          await update(parsedDate);
          context.emit("validate");
        } else {
          const generalAssemblyEvent = await create(parsedDate);
          if (generalAssemblyEvent) {
            router.push({
              name: ROUTE_NAMES.EventsGeneralAssemblyDetails,
              params: {
                productId: product.value.id,
                id: generalAssemblyEvent.id,
              },
            });
          }
          context.emit("validate");
        }
      }
    }

    return {
      convocationMedium,
      validate,
      generalAssemblyDate,
      generalAssemblyHour,
      generalAssemblyAddress,
      generalAssemblyConvocationMethod,
      validateFormat,
      today,
    };
  },
});
