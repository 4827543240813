import { CoreStore } from "@/store/modules/Core.store";
import { Ref, SetupContext } from "@vue/composition-api";
import { Dictionary } from "lodash";

export interface ProductTourStep {
  target: string;
  content: string;
  params: {
    placement: string;
  };
  offset?: number;
  before: Function;
}

export interface SkipStep {
  conditionToSkip: boolean;
  stepNumberToSkip: number;
}

export function useProductTourSteps(
  context: SetupContext,
  coreStore: CoreStore,
  overlay: Ref<boolean>
): {
  steps: Dictionary<ProductTourStep>;
  generateCallbacksPrevNext: (
    nameTour: string,
    skips: SkipStep[]
  ) => { onPreviousStep: Function; onNextStep: Function };
} {
  async function navigateBefore(
    routeName: string,
    params: string,
    openMenuMobile = false
  ): Promise<void> {
    return new Promise<void>((resolve) => {
      let wait = 0;
      if (coreStore.isOpenMobileMenu && !openMenuMobile) {
        coreStore.closeMobileMenu();
        wait = 500;
      }
      if (!coreStore.isOpenMobileMenu && openMenuMobile) {
        coreStore.openMobileMenu();
        wait = 500;
      }
      if (context.root.$route.name !== routeName) {
        context.root.$router.push({
          name: routeName,
          query: { welcome: params },
        });
        wait = 750;
      } else {
        context.root.$router.push({
          query: { welcome: params },
        });
      }
      if (wait) setTimeout(() => resolve(), wait);
      else resolve();
    });
  }

  async function retryOnTarget(target: string, timeout = 10000): Promise<void> {
    return new Promise<void>((resolve) => {
      let targetFound = document.querySelector(target);
      if (!targetFound) {
        let timerEnd = false;
        const timer = setTimeout(() => (timerEnd = true), timeout);
        const retry = new Promise<void>((resFound) => {
          (function retryLoop(res) {
            targetFound = document.querySelector(target);
            setTimeout(() => {
              if (!timerEnd && !targetFound) retryLoop(res);
              else res();
            }, 500);
          })(resFound);
        });
        retry.then(() => {
          clearTimeout(timer);
          resolve();
        });
      } else {
        resolve();
      }
    });
  }

  const stepTasks = {
    target: "#productTour-tasksTitle",
    content: `Découvrez l'espace "Mes tâches" : c'est ici que vous trouverez les actions à réaliser pour bien démarrer sur Ownily et pour clôturer votre année d'exercice le moment venu. 🚀<br/><br/>
    Accordez un peu de temps à cette étape : le bon paramétrage de vos Associés, Biens, Locataires est essentiel pour éviter des anomalies par la suite.`,
    params: {
      enableScrolling: false,
      placement: "bottom-start",
      highlight: false,
    },
    before: async (action: string) => {
      await navigateBefore("Dashboard", "tasks");
      if (action === "start") overlay.value = true;
      await retryOnTarget("#productTour-tasksTitle");
    },
  };

  const stepSettingsSCI = {
    target: "#productTour-settingsSCI",
    content: `Complétez ou modifiez ici les informations de la SCI  en cliquant sur ce bouton de parametrage. ⚙`,
    params: {
      enableScrolling: false,
      placement: "bottom-end",
      highlight: false,
    },
    before: async (action: string) => {
      await navigateBefore("Dashboard", "sci");
      if (action === "start") overlay.value = true;
      await retryOnTarget("#productTour-settingsSCI");
    },
  };

  const stepActivities = {
    target: "#productTour-activitiesTitle",
    content: `Obtenez une vue d'ensemble sur votre activité : suivez vos transactions, vos documents et vos échéances sur l’année en cours pour piloter en temps réel votre trésorerie. 📊`,
    params: {
      enableScrolling: false,
      placement: "bottom-start",
      highlight: false,
    },
    before: async (action: string) => {
      await navigateBefore("Dashboard", "activities");
      if (action === "start") overlay.value = true;
      await retryOnTarget("#productTour-activitiesTitle");
    },
  };

  const stepSubscription = {
    target: "#productTour-subscription",
    content: `Suivez l'avancement de votre période d'essai et choisissez votre abonnement à la fin de l'essai (ou avant) en cliquant sur le bouton Souscrire. 💡<br/><br/>
    Retrouvez également votre abonnement dans Mon Profil > Mes Abonnements.`,
    params: {
      enableScrolling: false,
      placement: "bottom-start",
      highlight: true,
    },
    before: async (action: string) => {
      await navigateBefore("Products", "subscription");
      if (action === "start") overlay.value = true;
      await retryOnTarget("#productTour-subscription");
    },
  };

  const stepBank = {
    target: "#banks-section",
    content: `C’est ici que vous connectez Ownily et le(s) compte(s) bancaire(s) de votre SCI, puis tenez à jour la connexion pour récupérer automatiquement les transactions en toute sécurité. 🔒 <br/><br/>
    Ownily vous notifie chaque fois que vous devez réactiver la synchronisation, conformément à la réglementation.`,
    params: {
      enableScrolling: false,
      placement: "bottom-start",
      highlight: true,
    },
    before: async (action: string) => {
      await navigateBefore("Products", "bank");
      if (action === "start") overlay.value = true;
      await retryOnTarget("#banks-section");
    },
  };

  const stepTransactions = {
    target: "#productTour-transactionsTitle",
    content: `Une fois le compte synchronisé, retrouvez ici les opérations remontées par votre banque.<br/><br/>
    Le bouton rouge vous indique en temps réel les transactions en attente de catégorisation. 🔴
    Le bouton vert vous indique nos suggestions de catégorisation. 🟢<br/><br/>
    💡 Si vous ne souhaitez pas connecter un compte bancaire dès votre essai, on vous explique comment ajouter des transactions manuellement dès la prochaine étape`,
    params: {
      enableScrolling: false,
      placement: "bottom-start",
      highlight: false,
    },
    before: async (action: string) => {
      await navigateBefore("Transactions", "transactions");
      if (action === "start") overlay.value = true;
      await retryOnTarget("#productTour-transactionsTitle");
    },
  };

  const stepAddTransaction = {
    target: "#productTour-addTransactionAction",
    content: `L'historique n'est pas complet ou vous souhaitez ajouter une transaction qui n'apparaît pas sur le compte bancaire de la SCI ? Ou bien vous n’avez pas encore connecté la Banque ?<br/><br/>
    En cliquant ici, vous pourrez ajouter des transactions à la volée ou importer un fichier complet. 📂`,
    params: {
      enableScrolling: false,
      placement: "bottom-center",
      highlight: true,
    },
    before: async (action: string) => {
      await navigateBefore("Transactions", "transactions-add");
      if (action === "start") overlay.value = true;
      await retryOnTarget("#productTour-addTransactionAction");
    },
  };

  const stepCerfa2072 = {
    target: "#productTour-tax2072Title",
    content: `Lors de la catégorisation de vos transactions bancaires, la déclaration des revenus de votre SCI (CERFA 2072) s'alimente et se met à jour en temps réel grâce à Ownily 💡`,
    params: {
      enableScrolling: false,
      placement: "bottom",
      highlight: false,
    },
    before: async (action: string) => {
      await navigateBefore("SciIncome", "cerfa-2072");
      if (action === "start") overlay.value = true;
      await retryOnTarget("#productTour-tax2072Title");
    },
  };

  const stepShowTransactionsCerfa2072 = {
    target: "#productTour-eye:first-child",
    content: `Vous pourrez à tout moment vérifier les montants de la déclaration de votre SCI en cliquant sur l’icône ŒIL 👁 au bout de chaque ligne.`,
    params: {
      enableScrolling: true,
      placement: "bottom",
      highlight: true,
    },
    offset: -300,
    before: async (action: string) => {
      await navigateBefore("SciIncome", "verif-cerfa2072");
      if (action === "start") overlay.value = true;
      await retryOnTarget("#productTour-eye:first-child");
    },
  };

  const stepAccounting = {
    target: "#productTour-accountingTitle",
    content: `En parallèle de la CERFA, la comptabilité de votre activité s'alimente également : solde, trésorerie, état mensuel, détail des recettes et dépenses...<br/><br/>
    Vous choisissez d’afficher ces données sur l’ensemble de la SCI ou sur 1 Bien en particulier`,
    params: {
      enableScrolling: false,
      placement: "bottom-start",
      highlight: false,
    },
    before: async (action: string) => {
      await navigateBefore("Accounting", "accounting");
      if (action === "start") overlay.value = true;
      await retryOnTarget("#productTour-accountingTitle");
    },
  };

  const stepAgo = {
    target: "#productTour-AgoTitle",
    content: `Chaque année, Ownily vous aide à organiser l’Assemblée Générale Ordinaire de votre SCI dans les règles de l'art. Générer et archiver les documents obligatoires devient un jeu d'enfant. 📄✨`,
    params: {
      enableScrolling: false,
      placement: "bottom-start",
      highlight: false,
    },
    before: async (action: string) => {
      await navigateBefore("EventsGeneralAssembly", "events-ag");
      if (action === "start") overlay.value = true;
      await retryOnTarget("#productTour-AgoTitle");
    },
  };

  const stepDocuments = {
    target: "#productTour-documentsTitle",
    content: `Les documents obligatoires générés par Ownily (AG, déclarations fiscales, comptabilité...) se retrouvent dans cet espace documentaire sécurisé. Vous pouvez également importer vos propres documents et justificatifs.`,
    params: {
      enableScrolling: false,
      placement: "bottom-start",
      highlight: false,
    },
    before: async (action: string) => {
      await navigateBefore("Documents", "documents");
      if (action === "start") overlay.value = true;
      await retryOnTarget("#productTour-documentsTitle");
    },
  };

  const stepEventsYearEnd = {
    target: "#productTour-AgeTitle",
    content: `Pour finir, réalisez la clôture annuelle de l’exercice et préparez les déclarations fiscales (disponible en début d'exercice N+1) en suivant pas à pas les étapes.`,
    params: {
      enableScrolling: false,
      placement: "top-start",
      highlight: false,
    },
    before: async (action: string) => {
      await navigateBefore("EventsYearEnd", "event-year-end");
      if (action === "start") overlay.value = true;
      await retryOnTarget("#productTour-AgeTitle");
    },
  };

  const stepProfile = {
    target: "#productTour-profilMenu",
    content: `Pour modifier vos informations personnelles, accédez à votre profil.`,
    params: {
      enableScrolling: false,
      placement: "bottom-start",
      highlight: true,
    },
    before: async (action: string) => {
      await navigateBefore("Dashboard", "profile");
      if (action === "start") overlay.value = true;
      await retryOnTarget("#productTour-profilMenu");
    },
  };

  const stepHelpCenter = {
    target: "#productTour-helpCenter",
    content: `Pour obtenir de l'aide contextuelle et être éclairé sur l'utilisation d'Ownily, accédez à notre centre d’aide. 💡<br/><br/>
    <span style="font-style: italic;">Cet espace est alimenté en continu grâce à vos suggestions.</span>`,
    params: {
      enableScrolling: false,
      placement: "bottom-end",
      highlight: true,
    },
    before: async (action: string) => {
      await navigateBefore("Dashboard", "help-center");
      if (action === "start") overlay.value = true;
      await retryOnTarget("#productTour-helpCenter");
    },
  };

  const stepHelpers = {
    target: "#productTour-helpers",
    content: `Une question ou une recommandation ? 🔍<br/>
    N'hésitez pas à nous contacter via le live-chat disponible ici. Nous ferons de notre mieux pour vous aider.`,
    params: {
      enableScrolling: false,
      placement: context.root.$vuetify.breakpoint.smAndDown
        ? "bottom-center"
        : "bottom-end",
      highlight: true,
    },
    before: async (action: string) => {
      await navigateBefore(
        "Dashboard",
        "helpers",
        context.root.$vuetify.breakpoint.smAndDown
      );
      if (action === "start") overlay.value = true;
      await retryOnTarget("#productTour-helpers");
    },
  };

  const stepEnd = {
    target: "#center",
    header: {
      title: "A vous de jouer !",
    },
    content: `Nous vous avons présenté l’essentiel à connaître pour vous permettre de tester Ownily. C'est à vous de prendre la main ! 😇<br/><br/>
    <span style="font-style: italic;">L'équipe Ownily</span>`,
    params: {
      placement: "center",
      highlight: false,
    },
    before: async (action: string) => {
      await navigateBefore("Dashboard", "end-tour");
      if (action === "start") overlay.value = true;
      await retryOnTarget("#center");
    },
  };

  const generateCallbacksPrevNext = (nameTour: string, skips: SkipStep[]) => {
    const callbacks = {
      onPreviousStep: (currentStep: number) => {
        skips.forEach((skip) => {
          if (currentStep === skip.stepNumberToSkip + 1 && skip.conditionToSkip)
            context.root.$tours[nameTour].start(
              Number(skip.stepNumberToSkip - 1).toString()
            );
        });
      },
      onNextStep: (currentStep: number) => {
        skips.forEach((skip) => {
          if (currentStep === skip.stepNumberToSkip - 1 && skip.conditionToSkip)
            context.root.$tours[nameTour].start(
              Number(skip.stepNumberToSkip + 1).toString()
            );
        });
      },
    };
    return callbacks;
  };

  return {
    steps: {
      stepTasks,
      stepSettingsSCI,
      stepActivities,
      stepSubscription,
      stepBank,
      stepTransactions,
      stepAddTransaction,
      stepCerfa2072,
      stepShowTransactionsCerfa2072,
      stepAccounting,
      stepAgo,
      stepEventsYearEnd,
      stepProfile,
      stepDocuments,
      stepHelpCenter,
      stepHelpers,
      stepEnd,
    },
    generateCallbacksPrevNext,
  };
}
