



















































import { TaskGroupLocal } from "@/models";
import {
  accountingPeriodsStore,
  productsStore,
  assembliesStore,
  tasksStore,
} from "@/store";
import {
  GeneralAssemblyEvent,
  getReferredIdByTypeWithReferences,
  TaskStatus,
  TaskTypeReference,
} from "@edmp/api/";
import { computed, ComputedRef, defineComponent } from "@vue/composition-api";
import EventsGeneralAssemblyCard from "./list/EventsGeneralAssemblyCard.vue";
import EventsGeneralAssemblyHeader from "./list/EventsGeneralAssemblyHeader.vue";

export default defineComponent({
  name: "EventsGeneralAssemblyList",
  components: {
    EventsGeneralAssemblyHeader,
    EventsGeneralAssemblyCard,
  },
  setup() {
    const getTaskGroupByEvent = function (gae: GeneralAssemblyEvent) {
      const taskGroup: ComputedRef<TaskGroupLocal | undefined> = computed(() =>
        tasksStore.assemblyTaskGroup.find((taskGroup) => {
          return (
            taskGroup.groupCode ===
              `event.general-assembly${
                gae.pattern === "yearEnd" ? ".year-end" : ""
              }` &&
            taskGroup.tasksLocal.some(
              (task) =>
                getReferredIdByTypeWithReferences(
                  task.references,
                  TaskTypeReference.product
                ) === productsStore.currentId &&
                getReferredIdByTypeWithReferences(
                  task.references,
                  TaskTypeReference.accountingPeriod
                ) === accountingPeriodsStore.currentId &&
                (gae.pattern === "yearEnd" ||
                  getReferredIdByTypeWithReferences(
                    task.references,
                    TaskTypeReference.assembly
                  ) === gae.id)
            )
          );
        })
      );
      return taskGroup;
    };

    const generalAssemblyEventNotCompleted: ComputedRef<
      GeneralAssemblyEvent[] | undefined
    > = computed(() =>
      assembliesStore.generalAssemblyEvents
        .filter(
          (gae) => gae.accountingPeriodId === accountingPeriodsStore.currentId
        )
        .filter((gae) => {
          const taskGroup: ComputedRef<TaskGroupLocal | undefined> =
            getTaskGroupByEvent(gae);
          if (taskGroup?.value?.status != TaskStatus.COMPLETED) return true;
          else return false;
        })
    );

    const generalAssemblyEventCompleted: ComputedRef<
      GeneralAssemblyEvent[] | undefined
    > = computed(() =>
      assembliesStore.generalAssemblyEvents
        .filter(
          (gae) => gae.accountingPeriodId === accountingPeriodsStore.currentId
        )
        .filter((gae) => {
          const taskGroup: ComputedRef<TaskGroupLocal | undefined> =
            getTaskGroupByEvent(gae);
          if (taskGroup?.value?.status == TaskStatus.COMPLETED) return true;
          else return false;
        })
    );

    return {
      generalAssemblyEventNotCompleted,
      generalAssemblyEventCompleted,
    };
  },
});
