













































































































































import {
  computed,
  defineComponent,
  onBeforeMount,
  PropType,
  ref,
  Ref,
  watch,
} from "@vue/composition-api";
import {
  getReferredIdByTypeWithReferences,
  TaskTypeReference,
  TaskCode,
  TaxRegime,
  isCommissionedBeforeAmortizationRecovery,
} from "@edmp/api";

import FixedAssetAmortisationModal from "../fixedAssetAmortisation/FixedAssetAmortisationModal.vue";
import Title from "@/components/core/title/Title.vue";
import FixedAssetForm from "./FixedAssetForm.vue";

import {
  productsStore,
  fixedAssetAmortisationsStore,
  accountingPeriodsStore,
  fixedAssetsStore,
  tasksStore,
} from "@/store";

import FixedAssetAmortisations from "../fixedAssetAmortisation/FixedAssetAmortisations.vue";

import PageHeader from "../../atom/PageHeader.vue";
import router from "@/router";
import { ROUTE_NAMES } from "@/router/routes";

export default defineComponent({
  name: "FixedAsset",
  components: {
    Title,
    FixedAssetForm,
    FixedAssetAmortisations,
    PageHeader,
    FixedAssetAmortisationModal,
  },
  props: {
    productId: {
      type: String,
      required: true,
    },
    fixedAssetId: {
      type: String as PropType<string | null>,
      required: true,
    },

    fixedAssetAmortisationId: { type: String, required: false },
  },
  setup(props, context) {
    const isLoading = ref(true);
    const isIS = computed(
      () =>
        accountingPeriodsStore.currentAccountingPeriod?.taxRegime ===
        TaxRegime.IS_2065
    );
    const isLMNP = computed(
      () =>
        accountingPeriodsStore.currentAccountingPeriod?.taxRegime ===
        TaxRegime.LMNP_2031
    );
    const fixedAsset = computed(() =>
      fixedAssetsStore.getFixedAsset(props.fixedAssetId)
    );
    const isEditable: Ref<boolean> = ref(false);

    const openAmortisationModal: Ref<boolean> = ref(false);

    const openModal: Ref<boolean> = ref(false);

    const setEditing = (val: boolean) => (isEditable.value = val);

    const amortizationRecoveryTask = computed(() =>
      tasksStore.completedTasks?.find(
        (task) =>
          task.code === TaskCode.AmortizationsResumptionPostAmortizationsUpdate
      )
    );
    const modificationDisabledMessage = computed(() => {
      const amortizationRecoveryTaskAccountingperiod =
        accountingPeriodsStore.accountingPeriods.find(
          (acc) =>
            acc.id ===
            getReferredIdByTypeWithReferences(
              amortizationRecoveryTask.value?.references,
              TaskTypeReference.accountingPeriod
            )
        );
      if (amortizationRecoveryTaskAccountingperiod) {
        const year = new Date(
          amortizationRecoveryTaskAccountingperiod.endAt
        ).getFullYear();
        return (
          "Votre clôture de l’année " +
          year +
          " a été faite, les amortissements ne sont plus modifiables pour assurer le principe de continuité de la comptabilité"
        );
      }
    });
    const isModificationDisabled = computed(() => {
      return (
        fixedAsset.value &&
        amortizationRecoveryTask.value &&
        isCommissionedBeforeAmortizationRecovery(
          fixedAsset.value,
          amortizationRecoveryTask.value
        )
      );
    });

    // Amortisation
    const fixedAssetAmortisationList = computed(() =>
      fixedAssetAmortisationsStore.getFixedAssetAmortisationsByFixedAssetId(
        props.fixedAssetId
      )
    );
    const selectedAmortisation = ref(undefined);
    function selectAmortisation(amortisation) {
      selectedAmortisation.value = amortisation;
      openAmortisationModal.value = true;
    }

    function closeAmortisationModal() {
      openAmortisationModal.value = false;
      selectedAmortisation.value = undefined;
    }
    function validateAmortisationModal() {
      closeAmortisationModal();
    }
    const goToTheNewFixedAsset = async (fixedAssetId) => {
      if (props.fixedAssetId === "new" && fixedAssetId != undefined) {
        router.push({
          name: ROUTE_NAMES.FixedAsset,
          params: {
            productId: productsStore.currentId,
            fixedAssetId: fixedAssetId,
          },
        });
      }
    };
    watch(
      () => productsStore.currentId,
      () => context.root.$router.push({ name: ROUTE_NAMES.FixedAssets }),
      { deep: true }
    );

    watch(
      () => [fixedAssetAmortisationList, props.fixedAssetAmortisationId],
      () => {
        if (props.fixedAssetAmortisationId) {
          const fixedAssetAmortisation = fixedAssetAmortisationList.value.find(
            (fixedAssetAmortisation) =>
              fixedAssetAmortisation.id === props.fixedAssetAmortisationId
          );
          if (fixedAssetAmortisation) {
            selectAmortisation(fixedAssetAmortisation);
          }
        }
      },
      { deep: true }
    );

    // Init
    const init = async () => {
      isLoading.value = true;
      if (props.fixedAssetId === "new") {
        isEditable.value = true;
      } else {
        try {
          await fixedAssetsStore.getFixedAsset(props.fixedAssetId);
        } catch (err) {
          console.error(err);
          context.root.$router.push({ name: ROUTE_NAMES.FixedAssets });
        }

        isEditable.value = false;
      }

      isLoading.value = false;
    };

    watch(
      () => [props],
      async () => await init(),
      {
        deep: true,
      }
    );

    onBeforeMount(async () => await init());

    const isAmortisationExist = computed(
      () => fixedAssetAmortisationList.value.length === 0
    );

    return {
      ROUTE_NAMES,
      isIS,
      isLMNP,
      fixedAsset,
      isEditable,
      setEditing,
      isLoading,
      openModal,
      openAmortisationModal,
      selectedAmortisation,
      selectAmortisation,
      closeAmortisationModal,
      validateAmortisationModal,
      isAmortisationExist,
      goToTheNewFixedAsset,
      modificationDisabledMessage,
      isModificationDisabled,
    };
  },
});
