var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"task",attrs:{"test-e2e":("task_activity_" + (_vm.taskLocal.code))}},[_c('div',{staticClass:"task-content"},[_c('div',{staticClass:"position mb-3"},[_c('h4',[_vm._v(" "+_vm._s(_vm.taskLocal.title)+" "),(_vm.taskLocal.isOptional)?_c('BadgeOptional'):_vm._e()],1),_c('TaskAction',{attrs:{"taskLocal":_vm.taskLocal,"resolveTask":_vm.resolveTask}})],1),_c('div',[_c('HelpingMessage',{attrs:{"title":"En savoir plus","opened":_vm.taskLocal.learnMoreOpened}},[_c('div',{domProps:{"innerHTML":_vm._s(_vm.taskLocal.learnMore)}})])],1)]),_c('div',{staticClass:"task-action"},[(
          _vm.taskLocal.status === _vm.TaskStatus.WAITING ||
          (_vm.taskLocal.type === _vm.TypeTask.STANDARD &&
            _vm.taskLocal.status === _vm.TaskStatus.PENDING)
        )?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"gray","fab":"","small":"","depressed":"","outlined":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-clock-alert-outline")])],1)]}}],null,false,68628929)},[_c('span',[_vm._v("En attente")])]):_vm._e(),(
          _vm.taskLocal.type === _vm.TypeTask.FLOW &&
          _vm.taskLocal.status === _vm.TaskStatus.PENDING
        )?_c('v-btn',{attrs:{"disabled":!!_vm.taskLocal.isDisabled,"color":"primary","depressed":"","loading":_vm.validationInProgress},on:{"click":function($event){return _vm.validateTask()}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-check")]),_vm._v(" Je valide ")],1):_vm._e(),(_vm.taskLocal.status === _vm.TaskStatus.COMPLETED)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"success","fab":"","small":"","depressed":"","outlined":"","test-e2e":("task_activity_" + (_vm.taskLocal.code) + "_validate")}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-check")])],1)]}}],null,false,90198728)},[_c('span',[_vm._v("Validé")])]):_vm._e()],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }