














































import { TaskGroupLocal } from "@/models";
import { TaskStatus } from "@edmp/api";
import { computed, defineComponent, PropType } from "@vue/composition-api";
import GroupAction from "./GroupAction.vue";

export default defineComponent({
  name: "Group",
  components: { GroupAction },
  props: {
    taskGroupLocal: {
      type: Object as PropType<TaskGroupLocal>,
      required: true,
    },
    isDisable: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    return {
      nbTasks: computed(
        () =>
          props.taskGroupLocal.tasksLocal.filter((task) => !task.isComingSoon)
            .length
      ),
      nbTasksDone: computed(
        () =>
          props.taskGroupLocal.tasksLocal.filter(
            (task) => !task.isComingSoon && task.status === TaskStatus.COMPLETED
          ).length
      ),
      dueDate: computed(() => {
        if (props.taskGroupLocal.dueDateLabel) {
          return props.taskGroupLocal.dueDateLabel;
        }
      }),
    };
  },
});
