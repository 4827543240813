





































import { TaskGroupLocal, TaskLocal } from "@/models";
import { defineComponent, onBeforeMount, PropType } from "@vue/composition-api";
import Group from "./Group.vue";
import Task from "../task/Task.vue";
import { tasksNeedingBalanceSheetFetch } from "@/utils";
import { accountingBalanceSheetsStore } from "@/store";
import { OmitField, TasksService } from "@edmp/api";

export default defineComponent({
  name: "TaskGroup",
  components: { Group, Task },
  props: {
    taskGroupLocal: {
      type: Object as PropType<TaskGroupLocal>,
      required: true,
    },
    isDisable: {
      type: Boolean,
      default: false,
    },
    displayTasks: {
      type: Boolean,
      default: false,
    },
    resolveTask: {
      type: Function as PropType<
        (
          taskLocal: TaskLocal,
          data?: OmitField<Required<TasksService.ValidateIn>, "id">
        ) => Promise<void>
      >,
      required: false,
    },
  },
  setup(props) {
    onBeforeMount(async () => {
      if (
        props.taskGroupLocal.tasksLocal.find((task) =>
          tasksNeedingBalanceSheetFetch.includes(task.code)
        )
      ) {
        await accountingBalanceSheetsStore.fetchAccountingBalanceSheets();
      }
    });
    return {
      disabledGroup: props.taskGroupLocal.isDisabled || props.isDisable,
    };
  },
});
