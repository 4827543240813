


















import { TaskGroupLocal, TaskLocal } from "@/models";
import { OmitField, TasksService, TaskStatus } from "@edmp/api";
import { defineComponent, computed, PropType } from "@vue/composition-api";
import TaskGroup from "./group/TaskGroup.vue";

export default defineComponent({
  name: "TaskGroups",
  components: { TaskGroup },
  props: {
    taskGroups: {
      type: Array as PropType<TaskGroupLocal[]>,
      required: true,
    },
    isDisable: {
      type: Boolean,
      default: false,
    },
    resolveTask: {
      type: Function as PropType<
        (
          task: TaskLocal,
          data?: OmitField<TasksService.ValidateIn, "id">
        ) => Promise<void>
      >,
      required: false,
    },
  },
  setup(props) {
    const displayTaskForFirstTaskGroupNotCompleted = computed(() =>
      props.taskGroups.map((taskGroup, index, taskGroups) => {
        if (
          taskGroups.every(
            (taskGroup) => taskGroup.status === TaskStatus.WAITING
          )
        ) {
          return false;
        }
        if (taskGroup.status !== TaskStatus.COMPLETED) {
          if (index !== 0) {
            return (
              taskGroups[index - 1].status === TaskStatus.COMPLETED &&
              !taskGroup.isDisabled
            );
          }
          return true;
        }
        return false;
      })
    );

    return {
      displayTaskForFirstTaskGroupNotCompleted,
    };
  },
});
