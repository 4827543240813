



































import Title from "@/components/core/title/Title.vue";
import { ROUTE_NAMES } from "@/router/routes";
import {
  accountingPeriodsStore,
  productsStore,
  assembliesStore,
  tasksStore,
} from "@/store";
import {
  GeneralAssemblyEvent,
  getReferredIdByTypeWithReferences,
  TaskTypeReference,
  TaskCode,
} from "@edmp/api";
import {
  computed,
  ComputedRef,
  defineComponent,
  onMounted,
  watch,
  PropType,
  ref,
} from "@vue/composition-api";
import EventsGeneralAssemblyDocuments from "./EventsGeneralAssemblyDocuments.vue";
import EventsGeneralAssemblyInfos from "./EventsGeneralAssemblyInfos.vue";

export default defineComponent({
  name: "EventsGeneralAssemblyDetails",
  components: {
    Title,
    EventsGeneralAssemblyInfos,
    EventsGeneralAssemblyDocuments,
  },
  props: {
    productId: { type: String, required: true },
    id: { type: String, required: true },
    openAgPVModal: { type: String as PropType<"true"> },
  },
  setup(props, context) {
    const isLoading: ComputedRef<boolean> = computed(
      () => assembliesStore.loading
    );
    const isOpenAgPVModal = ref(Boolean(props.openAgPVModal));
    const event: ComputedRef<GeneralAssemblyEvent | undefined> = computed(() =>
      assembliesStore.getGeneralAssemblyEvent(props.id)
    );

    const name: ComputedRef<string> = computed(() => {
      if (event.value)
        if (event.value.type == "ordinary") {
          if (event.value.pattern == "yearEnd")
            return "Approbation des comptes annuelle";
          else return "Assemblée Générale Ordinaire";
        } else {
          return "Assemblée Générale Extraordinaire";
        }
      else return "";
    });

    function isMatchingCondition(task) {
      return (
        getReferredIdByTypeWithReferences(
          task.references,
          TaskTypeReference.product
        ) === productsStore.currentId &&
        getReferredIdByTypeWithReferences(
          task.references,
          TaskTypeReference.accountingPeriod
        ) === accountingPeriodsStore.currentId &&
        (event.value?.pattern === "yearEnd" ||
          getReferredIdByTypeWithReferences(
            task.references,
            TaskTypeReference.assembly
          ) === event.value?.id)
      );
    }

    const taskGroup = computed(() => {
      const taskGroup = tasksStore.assemblyTaskGroup.find((taskGroup) => {
        return (
          taskGroup.groupCode ===
            `event.general-assembly${
              event.value && event.value.pattern === "yearEnd"
                ? ".year-end"
                : ""
            }` && taskGroup.tasksLocal.some((task) => isMatchingCondition(task))
        );
      });
      if (taskGroup) {
        taskGroup.tasksLocal = taskGroup.tasksLocal.filter(
          (task) => task.code !== TaskCode.Create && isMatchingCondition(task)
        );
      }
      return taskGroup;
    });

    onMounted(async () => {
      await productsStore.switchProduct({ id: props.productId });
      if (!event.value)
        context.root.$router.push({ name: ROUTE_NAMES.EventsGeneralAssembly });
      else
        await accountingPeriodsStore.switchAccountingPeriod(
          event.value.accountingPeriodId
        );
    });

    watch(
      () => productsStore.currentId,
      () => {
        if (!event.value)
          context.root.$router.push({
            name: ROUTE_NAMES.EventsGeneralAssembly,
          });
      }
    );

    return {
      isLoading,
      name,
      event,
      taskGroup,
      isOpenAgPVModal,
      ROUTE_NAMES,
    };
  },
});
