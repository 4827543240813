


























































import { accountingPeriodsStore, productsStore, tasksStore } from "@/store";
import {
  getReferredIdByTypeWithReferences,
  TaskStatus,
  TaskTypeReference,
  TaskCode,
  TaskGroupCode,
} from "@edmp/api";
import {
  computed,
  ComputedRef,
  defineComponent,
  ref,
  Ref,
} from "@vue/composition-api";

interface OnChangeGeneraleAssemblyData {
  ordinary: { selected: boolean; yearEnd: boolean; other: boolean };
  extraordinary: boolean;
}
interface GeneraleAssemblySelectedStep {
  type: string;
  pattern: string;
}
export default defineComponent({
  name: "EventsGeneralAssemblySelectModal",
  setup(_, context) {
    const selectedStep: Ref<GeneraleAssemblySelectedStep> = ref({
      type: "ordinary",
      pattern: "other",
    });
    const onChangeGeneraleAssemblyData: Ref<OnChangeGeneraleAssemblyData> = ref(
      {
        ordinary: { selected: true, yearEnd: false, other: true },
        extraordinary: false,
      }
    );

    const agYearEndAlreadyCreate: ComputedRef<boolean> = computed(
      () =>
        tasksStore.assemblyTaskGroup.findIndex((taskGroup) => {
          return (
            taskGroup.groupCode === TaskGroupCode.EventGeneralAssemblyYearEnd &&
            taskGroup.tasksLocal.some(
              (task) =>
                getReferredIdByTypeWithReferences(
                  task.references,
                  TaskTypeReference.product
                ) === productsStore.currentId &&
                getReferredIdByTypeWithReferences(
                  task.references,
                  TaskTypeReference.accountingPeriod
                ) === accountingPeriodsStore.currentId &&
                taskGroup.tasksLocal.findIndex(
                  (task) =>
                    task.code === TaskCode.Create &&
                    task.status === TaskStatus.PENDING
                ) >= 0
            )
          );
        }) === -1
    );

    function onChangeGeneraleAssembly(type: string) {
      switch (type) {
        case "ordinary": {
          onChangeGeneraleAssemblyData.value.ordinary.selected = true;
          onChangeGeneraleAssemblyData.value.extraordinary = false;
          selectedStep.value.type = type;
          break;
        }
        case "extraordinary": {
          onChangeGeneraleAssemblyData.value.ordinary.selected = false;
          onChangeGeneraleAssemblyData.value.extraordinary = true;
          selectedStep.value.type = type;
          break;
        }
        case "yearEnd": {
          onChangeGeneraleAssemblyData.value.ordinary.yearEnd = true;
          onChangeGeneraleAssemblyData.value.ordinary.other = false;
          selectedStep.value.pattern = type;
          break;
        }
        case "other": {
          onChangeGeneraleAssemblyData.value.ordinary.yearEnd = false;
          onChangeGeneraleAssemblyData.value.ordinary.other = true;
          selectedStep.value.pattern = type;
          break;
        }
        default: {
          break;
        }
      }
    }

    function submit() {
      context.emit("validateStep", selectedStep.value);
    }

    return {
      onChangeGeneraleAssemblyData,
      onChangeGeneraleAssembly,
      submit,
      agYearEndAlreadyCreate,
    };
  },
});
