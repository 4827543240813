
















































































































































import { coreStore, tasksStore, productsStore, activitiesStore } from "@/store";
import { FeedbackTypeEnum } from "@/store/modules/Core.store";
import { ActivitiesModel, Etablissement, ProductsModel } from "@edmp/api";
import {
  defineComponent,
  onBeforeMount,
  PropType,
  ref,
  Ref,
  watch,
} from "@vue/composition-api";
import SCISearch from "@/components/sign/signup/steps/SCISearch.vue";
import { onlyNumber } from "@/utils";
import { cloneDeep } from "lodash";

export default defineComponent({
  name: "ActivityInfos",
  components: {
    SCISearch,
  },
  props: {
    loading: { type: Boolean, default: false },
    productId: { type: String },
    isEditable: { type: Boolean, default: false },
    actions: { type: String as PropType<"create" | "update"> },
  },

  setup(props, context) {
    /**
     * ActivityData
     */
    const isLoading: Ref<boolean> = ref(props.loading);
    const activity: Ref<
      | ActivitiesModel.Activity<ActivitiesModel.ActivityTypes.COMPANY>
      | undefined
    > = ref();
    // Infos générales
    const isEditing: Ref<boolean> = ref(props.isEditable);
    // Infos générales - Autocompletion par recherche
    const searchProducts: Ref<boolean> = ref(false);

    const rules = ref({
      name: () =>
        !!activity.value?.name || "Renseignez le nom de votre société",
      siret: (): true | string =>
        !activity.value?.siret ||
        (!!activity.value?.siret &&
          onlyNumber(activity.value.siret) &&
          activity.value?.siret.length === 14) ||
        "Le format de votre SIRET est incorrect",
    });

    /**
     * Actions
     */
    function autoCompleteEnterprise(e: Etablissement): void {
      if (activity.value) {
        activity.value.siret = e.siret;

        if (e.uniteLegale) {
          activity.value.name = e.uniteLegale.denominationUniteLegale;
          activity.value.legalName = e.uniteLegale.denominationUniteLegale;
          activity.value.legalNature =
            e.uniteLegale.categorieJuridiqueUniteLegale;
          activity.value.ape =
            e.uniteLegale.activitePrincipaleUniteLegale.replace(".", "");
        }
        activity.value.address = {
          street: `${e.adresseEtablissement.numeroVoieEtablissement ?? ""}${
            e.adresseEtablissement.indiceRepetitionEtablissement ?? ""
          } ${e.adresseEtablissement.typeVoieEtablissement ?? ""} ${
            e.adresseEtablissement.libelleVoieEtablissement ?? ""
          }`,
          zip: e.adresseEtablissement.codePostalEtablissement ?? "",
          city: e.adresseEtablissement.libelleCommuneEtablissement ?? "",
        };
      }
      searchProducts.value = false;
      // focus on last autocompleted field
      requestAnimationFrame(() =>
        (context.refs.siretInput as HTMLElement).focus()
      );
    }

    async function updateActivity() {
      isLoading.value = true;

      // Validate
      let isValidRules = true;
      for (const rule in rules.value) {
        const isValidRule = rules.value[rule]();
        if (isValidRule !== true) {
          isValidRules = false;
          coreStore.displayFeedback({
            type: FeedbackTypeEnum.ERROR,
            message: isValidRule,
          });
        }
      }

      // Process
      if (isValidRules && activity.value?.name) {
        try {
          const activityToSave = { ...activity.value }; // ! Because reactivity clean this before send `updateActivity`
          context.emit("validate", true);
          if (activity.value?.id) {
            await productsStore.updateProduct({
              id: activityToSave.productId,
              name: activityToSave.name,
            });
            await activitiesStore.updateActivity(activityToSave);
          } else {
            await productsStore.createProduct({
              type: ProductsModel.ProductTypes.SCI,
              name: activityToSave.name,
              activity: activityToSave,
              accountingPeriods: [],
            });
          }
          await tasksStore.fetchTaskGroups({});
          coreStore.displayFeedback({
            message:
              "La mise à jour de votre activité a bien été prise en compte",
          });
          isEditing.value = false;
        } catch (err) {
          coreStore.displayFeedback({
            type: FeedbackTypeEnum.ERROR,
            message:
              "Une erreur est survenue lors de la mise à jour de votre SCI",
          });
          isLoading.value = false;
        } finally {
          isLoading.value = false;
        }
      }
      isLoading.value = false;
    }

    function cancelUpdate() {
      const currentProduct = productsStore.currentProduct;
      activity.value = Object.assign(
        {
          type: ActivitiesModel.ActivityTypes.COMPANY,
          address: { street: "", city: "", zip: "" },
        },
        {
          ...currentProduct?.activity,
        } as ActivitiesModel.Activity<ActivitiesModel.ActivityTypes.COMPANY>
      );
      isEditing.value = false;
    }

    /**
     * Init
     */
    async function init() {
      isLoading.value = true;
      if (!props.actions || props.actions === "update") {
        if (props.productId) {
          await productsStore.switchProduct({ id: props.productId });
        }
        activity.value = Object.assign(
          {
            type: ActivitiesModel.ActivityTypes.COMPANY,
            address: { street: "", city: "", zip: "" },
          },
          {
            ...cloneDeep(productsStore.currentProduct?.activity ?? {}),
          } as ActivitiesModel.Activity<ActivitiesModel.ActivityTypes.COMPANY>
        );
      } else {
        activity.value =
          productsStore.currentProduct?.status ===
          ProductsModel.ProductStatus.pending
            ? Object.assign(
                {
                  type: ActivitiesModel.ActivityTypes.COMPANY,
                  address: { street: "", city: "", zip: "" },
                },
                {
                  ...JSON.parse(
                    JSON.stringify(productsStore.currentProduct.activity)
                  ),
                }
              )
            : {
                type: ActivitiesModel.ActivityTypes.COMPANY,
                name: "",
                siret: "",
                ape: "",
                address: { street: "", zip: "", city: "" },
              };
        if (
          !(
            activity.value as ActivitiesModel.Activity<ActivitiesModel.ActivityTypes.COMPANY>
          ).id
        ) {
          isEditing.value = true;
        }
      }

      isLoading.value = false;
    }

    watch(
      () => [productsStore.currentProduct, props.actions],
      () => init(),
      { deep: true }
    );

    onBeforeMount(() => init());
    return {
      activity,
      isLoading,
      isEditing,
      searchProducts,
      onlyNumber,
      rules,
      autoCompleteEnterprise,
      cancelUpdate,
      updateActivity,
    };
  },
});
