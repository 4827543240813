































import { TaskGroupLocal } from "@/models";
import router from "@/router";
import { ROUTE_NAMES } from "@/router/routes";
import { accountingPeriodsStore, productsStore, tasksStore } from "@/store";
import {
  GeneralAssemblyEvent,
  getReferredIdByTypeWithReferences,
  TaskTypeReference,
  TaskStatus,
} from "@edmp/api";
import { computed, ComputedRef, defineComponent } from "@vue/composition-api";
import moment from "moment";

export default defineComponent({
  name: "EventsGeneralAssemblyCard",
  props: {
    event: {
      type: Object as () => GeneralAssemblyEvent,
      required: true,
    },
  },
  setup(props) {
    const name: ComputedRef<string> = computed(() => {
      if (props.event.type == "ordinary") {
        if (props.event.pattern == "yearEnd")
          return "Approbation des comptes annuelle";
        else return "Assemblée Générale Ordinaire";
      } else {
        return "Assemblée Générale Extraordinaire";
      }
    });

    const dateText: ComputedRef<string> = computed(() =>
      props.event.startAt
        ? moment(props.event.startAt).format("DD/MM/YYYY")
        : ""
    );

    const taskGroup: ComputedRef<TaskGroupLocal | undefined> = computed(() =>
      tasksStore.assemblyTaskGroup.find((taskGroup) => {
        return (
          taskGroup.groupCode ===
            `event.general-assembly${
              props.event.pattern === "yearEnd" ? ".year-end" : ""
            }` &&
          taskGroup.tasksLocal.some(
            (task) =>
              getReferredIdByTypeWithReferences(
                task.references,
                TaskTypeReference.product
              ) === productsStore.currentId &&
              getReferredIdByTypeWithReferences(
                task.references,
                TaskTypeReference.accountingPeriod
              ) === accountingPeriodsStore.currentId &&
              (props.event.pattern === "yearEnd" ||
                getReferredIdByTypeWithReferences(
                  task.references,
                  TaskTypeReference.assembly
                ) === props.event.id)
          )
        );
      })
    );

    const clickDetail = (event: GeneralAssemblyEvent) => {
      const productId = productsStore.currentId;
      router.push({
        name: ROUTE_NAMES.EventsGeneralAssemblyDetails,
        params: {
          productId,
          id: event.id,
        },
      });
    };

    return {
      name,
      dateText,
      taskGroup,
      TaskStatus,
      clickDetail,
    };
  },
});
