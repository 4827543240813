















































































































































































































































































































































































































































































































































































































































































































































































































































































































































































import { HelpingMessage } from "@/components/atom/helping";
import AccountingPeriodChoice from "@/components/core/accounting-period/AccountingPeriodChoice.vue";
import DocumentCard from "@/components/core/documents/DocumentCard.vue";
import { ArticleEnum, useCrisp } from "@/composables/crisp.usable";
import { documentsService } from "@/services";
import {
  accountingPeriodsStore,
  productsStore,
  realEstateAssetsStore,
  rentalBuildingsStore,
  subscriptionsStore,
  tasksStore,
  transactionsStore,
} from "@/store";
import { FilterKeys, onlyNumber } from "@/utils";
import {
  AccountingPeriod,
  Document,
  LedgerAccountEnum,
  RentalBuildingUpdate,
  SubscriptionsModel,
  SummaryTable,
  TaskStatus,
  TaskCode,
  TaskGroupCode,
} from "@edmp/api";
import {
  computed,
  ComputedRef,
  defineComponent,
  onBeforeMount,
  ref,
  Ref,
  watch,
} from "@vue/composition-api";
import { chain, cloneDeep, pickBy } from "lodash";
import moment from "moment";
import { taxDeclarationsService } from "@/services/TaxDeclarations.service";
import { ROUTE_NAMES } from "@/router/routes";
import TitleNew from "../../title/TitleNew.vue";
import TaxReturnLineHead2072 from "./TaxReturnLineHead.vue";
import DialogSubscribe from "../DialogSubscribe.vue";
import router from "@/router";

export default defineComponent({
  name: "TaxReturn2072",
  components: {
    TaxReturnLineHead2072,
    AccountingPeriodChoice,
    TitleNew,
    HelpingMessage,
    DocumentCard,
    DialogSubscribe,
  },
  setup(_, context) {
    const tempLine9bValue: Ref<string | number> = ref("0");
    const isEditing: Ref<boolean> = ref(false);
    const elLine9b: Ref<HTMLElement[] | null> = ref(null);
    const cerfa2072: Ref<Partial<SummaryTable>> = ref({});
    const documents: Ref<Document[] | undefined> = ref(undefined);
    const isLoading: Ref<Boolean> = ref(false);
    const isOpenDialogSubscribe = ref(false);

    const subscription = computed(() => {
      const subscription = subscriptionsStore.getCurrentSubscription;
      if (subscription?.plan.type === SubscriptionsModel.PlanType.Free) {
        isOpenDialogSubscribe.value = true;
      }
      return subscription;
    });

    const line9bCondition: ComputedRef<Boolean> = computed(
      () =>
        onlyNumber(tempLine9bValue.value) &&
        parseFloat(tempLine9bValue.value as string) <=
          cerfa2072Lines.value[1]?.data[3].value
    );
    const rules = computed(() => ({
      line9b: () =>
        line9bCondition.value ||
        "Ce champ n'accepte que des nombres et doit être inférieur ou égal au montant de la ligne 9",
    }));
    const taskFillCerfa2072 = computed(() => {
      const prepareCloseGroup = tasksStore.closureTaskGroup.find(
        (taskGroup) =>
          taskGroup.groupCode === TaskGroupCode.TaxReturnPrepareClose
      );
      if (prepareCloseGroup) {
        return prepareCloseGroup?.tasksLocal.find(
          (task) => task.code === TaskCode.FillCerfa2072
        );
      }
    });
    const taskBuildingConfigured = computed(() => {
      const prepareCloseGroup = tasksStore.closureTaskGroup.find(
        (taskGroup) =>
          taskGroup.groupCode === TaskGroupCode.TaxReturnPrepareClose
      );
      if (prepareCloseGroup) {
        return prepareCloseGroup?.tasksLocal.find(
          (task) => task.code === TaskCode.BuildingConfigured
        );
      }
    });
    const cerfa2072DeductionSpe = computed(() =>
      cerfa2072.value.rows
        ? pickBy(cerfa2072.value.rows, (value, key) =>
            key.startsWith("Deduction")
          )
        : {}
    );
    const cerfa2072R = computed(() =>
      cerfa2072.value
        ? chain(cerfa2072.value.rows)
            .pickBy((_, key) => key.length === 2 && key.startsWith("R"))
            .filter((value) => {
              return value?.meta?.sous_rubrique_2072_libell_ === "RESULTAT";
            })
            .sortBy("meta.champ_code2072", "desc")
            .value()
        : ({} as SummaryTable)
    );
    const accountingPeriodYear = computed(() => {
      const accountingPeriod =
        accountingPeriodsStore.currentAccountingPeriod as AccountingPeriod;
      return moment(accountingPeriod.startAt).format("YYYY");
    });
    const openline9bModal: Ref<boolean> = ref(false);
    const readonly: ComputedRef<boolean> = computed(
      () => tasksStore.isCurrentAccountingPeriodFillCerfa2072Completed
    );

    const selectedRentalBuildingIndex = ref(0);
    const rentalBuildings = computed(() =>
      rentalBuildingsStore
        .getRentalBuildingByAccountingPeriodId(accountingPeriodsStore.currentId)
        .map((rentalBuilding, index) => ({ ...rentalBuilding, index }))
    );
    const realEstateAssets = computed(
      () => realEstateAssetsStore.realEstateAssets
    );
    const realEstateAssetsNotAssign = computed(() =>
      rentalBuildingsStore.getRealEstateAssetsNotAssignedWithGoodDateByAccountingPeriodId(
        accountingPeriodsStore.currentId,
        ""
      )
    );

    async function activateTaskFillCerfa2072(): Promise<void> {
      if (taskFillCerfa2072.value) {
        tasksStore.activateTask(taskFillCerfa2072.value);
      }
    }

    async function getTaxReturn2072(): Promise<void> {
      if (!readonly.value) {
        if (productsStore.currentId && accountingPeriodsStore.currentId) {
          await taxDeclarationsService
            .get2072({
              accountingPeriodId: accountingPeriodsStore.currentId,
            })
            .then((res) => {
              cerfa2072.value = res;
            });
        }

        if (!productsStore.currentId) {
          taxDeclarationsService.get2072Template().then((res) => {
            cerfa2072.value = res;
          });
        }
      } else {
        // Cerfa2072 is Completed for current Accounting Period
        if (
          productsStore.currentId &&
          productsStore.currentProduct &&
          accountingPeriodsStore.currentAccountingPeriod
        ) {
          documents.value = await documentsService.list({
            productId: productsStore.currentId,
          });

          const docName = `${
            productsStore.currentProduct.name
          }_CERFA_2072_${new Date(
            accountingPeriodsStore.currentAccountingPeriod.endAt
          ).getFullYear()}.pdf`;

          documents.value = documents.value
            ?.filter(
              (doc: Document) =>
                doc.name === docName && doc.createdBy === "ownily"
            )
            .sort((a: Document, b: Document) => {
              // We sort documents if multiple Cerfa (reopening of accounting)
              if (b.updatedAt && !a.updatedAt) return -1;
              else if (!b.updatedAt && a.updatedAt) return 1;
              else if (b.updatedAt && a.updatedAt) {
                return (
                  new Date(b.updatedAt).getTime() -
                  new Date(a.updatedAt).getTime()
                );
              } else {
                return 0;
              }
            });
        }
      }
    }

    function displayOperations(code2072: string, refs?: string[]): void {
      if (code2072 === "R6") {
        transactionsStore.resetFilter();
        transactionsStore.addFilter({
          code: FilterKeys.CATEGORIES,
          filterParams: {
            label: `2072 - ${code2072}`,
            categories: [LedgerAccountEnum.N761000],
          },
        });
        context.root.$router.push({
          name: ROUTE_NAMES.Transactions,
        });
      }

      if (productsStore.currentId && accountingPeriodsStore.currentId && refs)
        taxDeclarationsService
          .list2072Transactions({
            accountingPeriodId: accountingPeriodsStore.currentId,
            name: code2072,
            refs,
          })
          .then((res) => {
            transactionsStore.resetFilter();
            transactionsStore.addFilter({
              code: FilterKeys.CERFA,
              filterParams: {
                ids: res,
                label: `2072 - ${code2072} - ${
                  rentalBuildings.value[selectedRentalBuildingIndex.value].name
                }`,
              },
            });
            context.root.$router.push({
              name: ROUTE_NAMES.Transactions,
            });
          });
    }

    const clickOpenHelp = () =>
      useCrisp().openArticle(ArticleEnum.TAX_RETURN_2072);

    const cerfa2072Lines = computed(() => {
      let cerfaRecettesData = {};
      if (cerfa2072.value) {
        cerfaRecettesData = chain(cerfa2072.value.rows)
          .pickBy((value, key) => key.startsWith("Ligne"))
          .filter((value) => {
            return value?.meta?.sous_rubrique_2072_libell_ === "RECETTES";
          })
          .sortBy((value) => {
            if (value)
              return parseInt(value.meta.champ_code2072.replace("Ligne ", ""));
            return 0;
          }, "desc")
          .value();
      }

      let cerfaDeductionsData = {};

      if (cerfa2072.value.rows) {
        cerfaDeductionsData = chain(cerfa2072.value.rows)
          .pickBy((value, key) => key.startsWith("Ligne"))
          .filter((value) => {
            return (
              value?.meta?.sous_rubrique_2072_libell_ ===
              "DÉDUCTIONS, FRAIS ET CHARGES"
            );
          })
          .sortBy((value) => {
            if (value)
              return parseInt(value.meta.champ_code2072.replace("Ligne ", ""));
            return 0;
          }, "desc")
          .value();

        const line9b = {
          meta: {
            champ_code2072: "Ligne 9 BIS",
            champ_libell__2072:
              "Dont dépenses de travaux de rénovation énergétique permettant à un bien de passer d'une classe énergétique E, F ou G à une classe A, B, C ou D",
          },
        };
        (cerfaDeductionsData as Array<object>).splice(4, 0, line9b);
      }

      const lines = {
        0: {
          title: "Recettes",
          data: cerfaRecettesData,
        },
        1: {
          title: "Déductions, frais et charges",
          data: cerfaDeductionsData,
        },
      };
      return lines;
    });

    const showCerfaEvent = (rentalBuildingId) => {
      const rentalBuildingsIndex = rentalBuildings.value.findIndex(
        (rentalBuilding) => rentalBuilding.id === rentalBuildingId
      );
      if (rentalBuildingsIndex !== -1) {
        selectedRentalBuildingIndex.value = rentalBuildingsIndex;
      }
    };

    function cancelLine9bUpdate() {
      isEditing.value = false;
      setLine9bToStoredValue();
    }

    async function confirmLine9bUpdate() {
      const originalRentalBuilding: RentalBuildingUpdate = cloneDeep(
        rentalBuildings.value[selectedRentalBuildingIndex.value]
      );
      tempLine9bValue.value = parseFloat(tempLine9bValue.value as string);
      originalRentalBuilding.line9bAmount = tempLine9bValue.value;
      if (line9bCondition.value === true) {
        isLoading.value = true;
        await rentalBuildingsStore.updateRentalBuilding(originalRentalBuilding);
        isLoading.value = false;
        setLine9bToStoredValue();
        isEditing.value = false;
      }
    }

    watch(selectedRentalBuildingIndex, () => {
      setLine9bToStoredValue();
    });

    function setLine9bToStoredValue() {
      if (
        rentalBuildings.value[selectedRentalBuildingIndex.value] &&
        rentalBuildings.value[selectedRentalBuildingIndex.value].line9bAmount
      ) {
        tempLine9bValue.value = rentalBuildings.value[
          selectedRentalBuildingIndex.value
        ].line9bAmount as number;
      } else {
        tempLine9bValue.value = 0;
      }
    }

    watch(
      () => [accountingPeriodsStore.currentId],
      () => {
        getTaxReturn2072();
        selectedRentalBuildingIndex.value = 0;
      },
      { deep: true }
    );

    onBeforeMount(async () => {
      await rentalBuildingsStore.fetchRentalBuildings({});
      getTaxReturn2072();
      setLine9bToStoredValue();
    });

    watch(elLine9b, () => {
      if (router.currentRoute.hash === "#line9b" && elLine9b.value) {
        elLine9b.value[0].scrollIntoView({
          behavior: "smooth",
          block: "center",
        });
        if (
          taskFillCerfa2072.value &&
          taskBuildingConfigured.value?.status === TaskStatus.COMPLETED &&
          taskFillCerfa2072.value.status === TaskStatus.WAITING
        ) {
          openline9bModal.value = true;
        }
      }
    });

    return {
      ROUTE_NAMES,
      watch,
      readonly,
      documents,
      rentalBuildings,
      selectedRentalBuildingIndex,
      realEstateAssets,
      realEstateAssetsNotAssign,
      displayOperations,
      cerfa2072,
      getTaxReturn2072,
      clickOpenHelp,
      showCerfaEvent,
      accountingPeriodYear,
      cerfa2072R,
      cerfa2072Lines,
      cerfa2072DeductionSpe,
      isEditing,
      elLine9b,
      openline9bModal,
      taskFillCerfa2072,
      activateTaskFillCerfa2072,
      tempLine9bValue,
      cancelLine9bUpdate,
      confirmLine9bUpdate,
      isLoading,
      TaskStatus,
      rules,
      subscription,
      SubscriptionPlanType: SubscriptionsModel.PlanType,
      isOpenDialogSubscribe,
    };
  },
});
