



















































































































































































































































































































































































import DatePicker from "@/components/atom/DatePicker.vue";
import { ArticleEnum, useCrisp } from "@/composables/crisp.usable";
import { ROUTE_NAMES } from "@/router/routes";
import {
  accountingPeriodsStore,
  productsStore,
  realEstateAmortisationsStore,
  realEstateAssetsStore,
  subscriptionsStore,
  tasksStore,
} from "@/store";
import {
  getMoment,
  getReferredIdByTypeWithReferences,
  isCommissionedBeforeAmortizationRecovery,
  NatureLabel,
  RealEstateAmortisation,
  RealEstateAssetUpdate,
  TaskTypeReference,
  TaskCode,
} from "@edmp/api";
import {
  computed,
  ComputedRef,
  defineComponent,
  nextTick,
  PropType,
  ref,
  Ref,
} from "@vue/composition-api";
import { format } from "date-fns";
import PageHeader from "../../atom/PageHeader.vue";
import Anomalies from "../anomalies/Anomalies.vue";
import Title from "../title/Title.vue";
import { HelpingMessage } from "@/components/atom/helping";
import Tag from "@/components/atom/Tag.vue";
import RealEstateAmortisationTable from "./RealEstateAmortisationTable.vue";
import RealEstateAmortisationForm from "./RealEstateAmortisationForm.vue";
import RealEstateAmortisations from "./RealEstateAmortisations.vue";
import { numberWithSpaces } from "@/utils";
import { VForm } from "@/models";
import { cloneDeep } from "lodash";

export default defineComponent({
  name: "RealEstateAmortisationLMNP",
  components: {
    Title,
    Tag,
    HelpingMessage,
    Anomalies,
    RealEstateAmortisations,
    RealEstateAmortisationTable,
    RealEstateAmortisationForm,
    PageHeader,
    DatePicker,
  },
  props: {
    productId: {
      type: String,
      required: true,
    },
    realEstateAssetId: {
      type: String as PropType<string | null>,
      required: true,
    },
    realEstateAmortisationId: { type: String, required: false },
    isFlow: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, context) {
    const isLoading = ref(true);
    const validateInProgress: Ref<boolean> = ref(false);
    const realEstateAsset = computed(() =>
      cloneDeep(
        realEstateAssetsStore.getRealEstateAsset(props.realEstateAssetId)
      )
    );
    const realEstateAssetNature = computed(() =>
      realEstateAsset.value?.nature !== undefined
        ? NatureLabel[realEstateAsset.value.nature]
        : "inconnue"
    );
    const realEstateAssetMarketPriceText = computed(() =>
      realEstateAsset.value?.marketPrice !== undefined &&
      realEstateAsset.value?.marketPrice !== 0
        ? `${numberWithSpaces(realEstateAsset.value?.marketPrice)} €`
        : "inconnu"
    );

    const realEstateAssetEntryDateActivityLmnp = computed(() =>
      realEstateAsset.value?.entryDateActivityLmnp !== undefined
        ? getMoment(realEstateAsset.value.entryDateActivityLmnp).format("LL")
        : "inconnue"
    );
    const today = ref(format(new Date(), "yyyy-MM-dd"));

    const scrollToElement = (elementId) =>
      nextTick(() => {
        const element = document.getElementById(elementId);
        if (element) {
          nextTick(() => {
            element.scrollIntoView({ behavior: "smooth" });
          });
        }
      });

    const amortizationRecoveryTask = computed(() =>
      tasksStore.completedTasks?.find(
        (task) =>
          task.code ===
            TaskCode.AmortizationsResumptionPostAmortizationsUpdate &&
          getReferredIdByTypeWithReferences(
            task.references,
            TaskTypeReference.product
          ) === productsStore.currentId
      )
    );
    const modificationDisabledMessage = computed(() => {
      const amortizationRecoveryTaskAccountingPeriod =
        accountingPeriodsStore.accountingPeriods.find(
          (acc) =>
            acc.id ===
            getReferredIdByTypeWithReferences(
              amortizationRecoveryTask.value?.references,
              TaskTypeReference.accountingPeriod
            )
        );
      if (amortizationRecoveryTaskAccountingPeriod) {
        const year = new Date(
          amortizationRecoveryTaskAccountingPeriod.endAt
        ).getFullYear();
        return (
          "Votre clôture de l’année " +
          year +
          " a été faite, les amortissements ne sont plus modifiables pour assurer le principe de continuité de la comptabilité"
        );
      }
    });
    const isModificationDisabled = computed(() => {
      return (
        realEstateAsset.value &&
        amortizationRecoveryTask.value &&
        isCommissionedBeforeAmortizationRecovery(
          realEstateAsset.value,
          amortizationRecoveryTask.value
        )
      );
    });

    const openArticleRealEstateAmortisationAddingLine = () => {
      return useCrisp().openArticle(
        ArticleEnum.REAL_ESTATE_AMORTISATION_RECOMMENDATION_ADD_LINE
      );
    };

    // Amortisation
    const realEstateAmortisationList: ComputedRef<RealEstateAmortisation[]> =
      computed(() =>
        realEstateAmortisationsStore.getRealEstateAmortisationsByRealEstateAssetId(
          props.realEstateAssetId
        )
      );
    const realEstateAmortisationPurchaseCosts: ComputedRef<RealEstateAmortisation> =
      computed(
        () =>
          realEstateAmortisationList.value.filter(
            (realEstateAmortisation) =>
              realEstateAmortisation.type === "purchase-costs"
          )[0]
      );
    const isAmortisationExist = computed(
      () => realEstateAmortisationList.value.length !== 0
    );
    const isEditableAmortisation: Ref<boolean> = ref(false);
    const setEditingAmortisation = (val: boolean) =>
      (isEditableAmortisation.value = val);
    const isEditablePurchaseCostsAmortisation: Ref<boolean> = ref(false);
    const setEditingPurchaseCostsAmortisation = (val: boolean) =>
      (isEditablePurchaseCostsAmortisation.value = val);
    const isEditable: Ref<boolean> = ref(!isAmortisationExist.value);
    const setEditing = (val: boolean) => (isEditable.value = val);
    async function validate() {
      validateInProgress.value = true;
      if (realEstateAsset.value && (context.refs.form as VForm).validate()) {
        await realEstateAssetsStore.updateRealEstateAsset(
          realEstateAsset.value as RealEstateAssetUpdate
        );
        if (realEstateAmortisationPurchaseCosts.value) {
          await realEstateAmortisationsStore.updateRealEstateAmortisation({
            ...realEstateAmortisationPurchaseCosts.value,
            value: realEstateAsset.value.boughtFee,
          });
        }

        setEditing(false);
        isEditableAmortisation.value = true;
        isEditablePurchaseCostsAmortisation.value = true;
        scrollToElement("amortisationDefaultTable-component");
      }
      validateInProgress.value = false;
    }

    return {
      ROUTE_NAMES,
      realEstateAsset,
      realEstateAssetNature,
      realEstateAssetMarketPriceText,
      realEstateAssetEntryDateActivityLmnp,
      isEditable,
      today,
      setEditing,
      isLoading,
      validateInProgress,
      isAmortisationExist,
      openArticleRealEstateAmortisationAddingLine,
      isEditableAmortisation,
      setEditingAmortisation,
      isEditablePurchaseCostsAmortisation,
      setEditingPurchaseCostsAmortisation,
      isModificationDisabled,
      modificationDisabledMessage,
      subscriptionsStore,
      accountingPeriodsStore,
      scrollToElement,
      validate,
      openArticleAmortisationRecommendationAddLine: () =>
        useCrisp().openArticle(
          ArticleEnum.REAL_ESTATE_AMORTISATION_RECOMMENDATION_ADD_LINE
        ),
    };
  },
});
