import { Document, model, Schema } from "mongoose";
import { ulid } from "ulid";
import { ActivitiesModel, BankAccountId } from "..";
import { decimal2JSON, IsoDate } from "./Common.model";
import { OperationModelSpec, TransactionModelSpec } from "./Definitions.model";
import { Transaction } from "./Transaction.model";

export type JournalComposedEntryId = string;
/**
 * `JournalEntryId` — ID for an accounting operation
 */
export type JournalEntryId = string;

/**
 * `AccountingPeriodId` — ID for an accounting period
 */
export type AccountingPeriodId = string;

export const PlanBankAccount = "512000";

/**
 * Account number enum ( represents also Category )
 *
 * This enum is used to "strongly type" ledger account number used in business rules in system
 */
export enum LedgerAccountEnum {
  N108000 = "108000", // Operator
  N110001 = "110001", // Carry forward NET (debit-credit)
  N110000 = "110000", // Exercise benefit past (Retained earning, credit balance N-1)
  N119000 = "119000", // Lost of exercise past (Postponement again, debit balance N-1)
  N120001 = "120001", // Result of the current exercise (debit-credit)
  N120000 = "120000", // Exercise benefit current
  N129000 = "129000", // Lost of exercise current
  N164000 = "164000", // Old Category replaced by 164100
  N164100 = "164100", // Automatized LOAN
  N164200 = "164200", // release of funds
  N401000 = "401000",
  N411000 = "411000",
  N445660 = "445660", // TVA deductible
  N445720 = "445720",
  N455000 = "455000", // contribution or refund Associate Account ( Compte Associé)
  N455010 = "455010", // expense report Associate Account (Note de Frais Compte Associé)
  N467000 = "467000", // Other receivables
  N471000 = "471000", // Suspense Account (Compte d'attente)
  N503000 = "503000", // Shares Banks
  N512000 = "512000", // Bank
  N606110 = "606110", // Water, Gaz, Electricity
  N618000 = "618000", // non-deductible miscellaneous expenses
  N661100 = "661100", // Removed et replaced by 616600 and 661110
  N616600 = "616600", // Insurance
  N627800 = "627800", // bank commission
  N661110 = "661110", // Interest
  N681110 = "681110", // Dowry. To death. Real estate. Incorporeal
  N708399 = "708399",
  N758000 = "758000", // Deductible expenses reimbursed
  N706000 = "706000",
  N706001 = "706001",
  N706101 = "706101",
  N761000 = "761000",
  N654000 = "654000",
  N444000 = "444000",
  N445620 = "445620",
  N445670 = "445670",
  N447000 = "447000",
  N615310 = "615310",
  N106100 = "106100",
  N580000 = "580000",
  N508000 = "508000",
  N201100 = "201100", // Purchase costs
  N201000 = "201000", // Establishment costs (non-deductible)
  N211100 = "211100", // Land - purchase/sale
  N213000 = "213000", // Acquisition of a property (price of the property net of costs)
  N213100 = "213100", // Buildings - structural work, roof, facade
  N213500 = "213500", // Install. technical electricity, water, elevators
  N215700 = "215700", // Fixtures and layouts of equipment and tools
  N218400 = "218400", // Interior layout, furnishings
  N280000 = "280000", // Amortization of purchase costs => 201100
  N280100 = "280100", // Amortization of incorporation costs => 201000
  N281310 = "281310", // Depreciation of buildings, shell, roof, facade => 213100
  N281350 = "281350", // Depreciation of technical installation electricity, elevators, water => 213500, 215700
  N281840 = "281840", // Depreciation layout => 218400
  N101100 = "101100", // Share capital
  N109000 = "109000", // Uncalled committed capital
  N165000 = "165000", // Deposits received
  N271000 = "271000", // Shares Banks
  N272000 = "272000", // Other financial investments
  N445710 = "445710", // VAT collected on turnover
  N445800 = "445800", // State VAT payment
  N781100 = "781100", // Amortizations Recovery
  N681120 = "681120", // Additional depreciation allowances for the recovery of depreciation on Ownily
  N708300 = "708300", // Miscellaneous revenue and subsidies
  N791400 = "791400", // Insurance benefits
  N622710 = "622710", // Deposits / guarantee costs
  N627100 = "627100", // Application fees / bank commissions
  N606300 = "606300", // Small equipment (furniture & kitchen appliances)
  N606400 = "606400", // Administrative supplies
  N614020 = "614020", // Copro charges - Adjustments
  N614010 = "614010", // Copro charges - Appeals
  N622610 = "622610", // Management fees
  N615200 = "615200", // Copro works
  N615210 = "615210", // Maintenance and Repair - Deductible
  N616100 = "616100", // Insurance - Contributions
  N622000 = "622000", // Acquisition costs (transfer duties, fees or commissions and deed costs)
  N671400 = "671400", // Charges not recovered
  N671000 = "671000", // Fines and Penalties
  N622700 = "622700", // Eviction or rehousing of a tenant
  N623700 = "623700", // Recordings and Publications
  N627200 = "627200", // Bank charges (excluding agios)
  N625100 = "625100", // Mileage charges
  N635121 = "635121", // Garbage tax
  N635125 = "635125", // Taxes and duties
  N775000 = "775000", // Proceeds from the sale of the Property (asset)
  N661600 = "661600", // Bank charges
  N675000 = "675000", // Net book value of items sold
  N695000 = "695000", // IS tax payment
  N491000 = "491000", // Provisions on unpaid rent
  N626000 = "626000", // Internet and telephone subscription
  N635110 = "635110", // CFE/CVAE payment
  TRASH = "000000", // Trash account
  N512100 = "512100", // Trash bank account
  UNKNOWN = "UNKNOWN",
}

const AmortisationAccountByAccount: { [key in LedgerAccountEnum]?: LedgerAccountEnum } = {
  [LedgerAccountEnum.N201100]: LedgerAccountEnum.N280000,
  [LedgerAccountEnum.N201000]: LedgerAccountEnum.N280100,
  [LedgerAccountEnum.N213100]: LedgerAccountEnum.N281310,
  [LedgerAccountEnum.N213500]: LedgerAccountEnum.N281350,
  [LedgerAccountEnum.N218400]: LedgerAccountEnum.N281840,
  [LedgerAccountEnum.N164200]: LedgerAccountEnum.N164100, // We want account 164100 to be considered as depreciation of account 164200. => https://gitlab.com/edmp/fonctionnel/-/issues/2052
  [LedgerAccountEnum.N512000]: LedgerAccountEnum.N512100,
};
export const getAmortisationAccountByAccount = (account: LedgerAccountEnum) => AmortisationAccountByAccount[account];

export const isObsoleteCategories = (accountNumber: string): boolean =>
  accountNumber === LedgerAccountEnum.N164000 || accountNumber === LedgerAccountEnum.N661100;
export const isPlanBankAccount = (accountNumber: string): boolean => accountNumber.startsWith(PlanBankAccount);
// Check is account is linked to associate bank account (need to retrieve balance, fec, ...)
export const isAssociateBankAccount = (accountNumber: string): boolean => accountNumber.startsWith("455") || accountNumber.startsWith(LedgerAccountEnum.N512100);
export const isReportExercise = (accountNumber: string): boolean => accountNumber === LedgerAccountEnum.N110001;
export const isResultExercise = (accountNumber: string): boolean => accountNumber === LedgerAccountEnum.N120001;
export const isAmortizationsRecovery = (accountNumber: string): boolean =>
  accountNumber === LedgerAccountEnum.N781100 || accountNumber === LedgerAccountEnum.N681120;
export const isAmortisationAccount = (accountNumber: string): boolean => accountNumber.startsWith("28");
export const isExpenseAccounts = (accountNumber: string): boolean => accountNumber.startsWith("6");
export const isProductAccounts = (accountNumber: string): boolean => accountNumber.startsWith("7");
export const isOperatorAccount = (accountNumber: string): boolean => accountNumber === LedgerAccountEnum.N108000;

const DoubleEntryAccountByAccount: { [key in LedgerAccountEnum]?: LedgerAccountEnum[] } = {
  [LedgerAccountEnum.N411000]: [
    LedgerAccountEnum.N706000,
    LedgerAccountEnum.N706001,
    LedgerAccountEnum.N706101,
    LedgerAccountEnum.N708399,
    LedgerAccountEnum.N758000,
    LedgerAccountEnum.N708300,
    LedgerAccountEnum.N775000,
    LedgerAccountEnum.N761000,
    LedgerAccountEnum.N791400,
  ],
  [LedgerAccountEnum.N401000]: [
    LedgerAccountEnum.N201000,
    LedgerAccountEnum.N201100,
    LedgerAccountEnum.N211100,
    LedgerAccountEnum.N213100,
    LedgerAccountEnum.N213500,
    LedgerAccountEnum.N215700,
    LedgerAccountEnum.N218400,
    LedgerAccountEnum.N271000,
    LedgerAccountEnum.N272000,
    LedgerAccountEnum.N280000,
    LedgerAccountEnum.N280100,
    LedgerAccountEnum.N281310,
    LedgerAccountEnum.N281350,
    LedgerAccountEnum.N281840,
    LedgerAccountEnum.N781100,
    LedgerAccountEnum.N681110,
    LedgerAccountEnum.N681120,
    LedgerAccountEnum.N622000,
    LedgerAccountEnum.N213000,
    LedgerAccountEnum.N615200,
    LedgerAccountEnum.N615210,
    LedgerAccountEnum.N615310,
    LedgerAccountEnum.N635121,
    LedgerAccountEnum.N635125,
    LedgerAccountEnum.N654000,
    LedgerAccountEnum.N695000,
    LedgerAccountEnum.N606110,
    LedgerAccountEnum.N606300,
    LedgerAccountEnum.N606400,
    LedgerAccountEnum.N614010,
    LedgerAccountEnum.N614020,
    LedgerAccountEnum.N622610,
    LedgerAccountEnum.N671000,
    LedgerAccountEnum.N671400,
    LedgerAccountEnum.N675000,
    LedgerAccountEnum.N622700,
    LedgerAccountEnum.N623700,
    LedgerAccountEnum.N627800,
    LedgerAccountEnum.N618000,
    LedgerAccountEnum.N625100,
    LedgerAccountEnum.N627200,
    LedgerAccountEnum.N616100,
    LedgerAccountEnum.N661100,
    LedgerAccountEnum.N661600,
  ],
};
export const getDoubleEntryAccount = (value: LedgerAccountEnum | string): LedgerAccountEnum => {
  for (const [key, values] of Object.entries(DoubleEntryAccountByAccount)) {
    if (values.includes(value as LedgerAccountEnum)) {
      return key as LedgerAccountEnum;
    }
  }
  return LedgerAccountEnum.UNKNOWN;
};

/**
 * `LedgerAccountNumber` — Ledger account number, as found in the chart of accounts (weak type)
 */
export type LedgerAccountNumber = LedgerAccountEnum | string;
/**
 * `LedgerAccountName` — Textual description of the account, as seen in the general
 * ledger's chart of accounts.
 */
export type LedgerAccountName = string;
export type AssetId = string;
export type RentalUnitId = string;
export type PartnerId = string;
export type RentalAgreementId = string;
export type TenantId = string;
export type RealEstateLoanId = string;
export type RealEstateAmortisationId = string;
export type DocumentId = string;
export type FixedAssetId = string;
export type BeneficiaryId = string;

/**
 * `LedgerAccount` — Entry in the Chart of Accounts / General Ledger
 */
export interface LedgerAccount {
  number?: LedgerAccountNumber;
  name?: LedgerAccountName;
}

export type CategorizationEntryParameters = {
  // Must exist in TypeReference
  bankAccount?: BankAccountId;
  realEstateAsset?: AssetId;
  rentalUnit?: RentalUnitId;
  rentalAgreement?: RentalAgreementId;
  tenant?: TenantId;
  partner?: PartnerId;
  realEstateLoan?: RealEstateLoanId;
  realEstateAmortisation?: RealEstateAmortisationId;
  supportingDocument?: DocumentId;
  fixedAsset?: FixedAssetId;
  beneficiary?: BeneficiaryId;
};

/**
 * `CategorizationEntry` — A categorization entry
 *
 * Depending on how the data entry is done, either:
 * - `amount` is present in all but one entries, and the amount for that entry is computed by subtraction.
 * - `amount` is present in all entries and the total amount must match the expected amount.
 *
 * All fields beyond `category` and `amount` are optional
 */
export type CategorizationEntry = {
  id?: string;
  account: LedgerAccountNumber;
  amount: number;
  accountName?: string;
} & CategorizationEntryParameters;

export type CategorizationEntryDocument = CategorizationEntry & Document<string>;

export const categorizationEntrySchema = new Schema<CategorizationEntry>(
  {
    _id: { type: String, default: () => ulid() },
    account: { type: String, required: true, index: true },
    amount: { type: Schema.Types.Decimal128, index: true },
    accountName: String,
    realEstateAsset: String,
    rentalUnit: String,
    rentalAgreement: String,
    tenant: String,
    partner: String,
    realEstateLoan: String,
    realEstateAmortisation: String,
    bankAccount: String,
    fixedAsset: String,
  },
  {
    toJSON: {
      versionKey: false,
      virtuals: true,
      transform(doc, ret: CategorizationEntryDocument) {
        ret.id = ret._id;
        decimal2JSON(ret);
        delete ret._id;
        return ret;
      },
    },
  }
);

export enum TypeReference {
  bankAccount = "bankAccount",
  rentalUnit = "rentalUnit",
  realEstateAsset = "realEstateAsset",
  rentalAgreement = "rentalAgreement",
  tenant = "tenant",
  partner = "partner",
  realEstateLoan = "realEstateLoan",
  realEstateAmortisation = "realEstateAmortisation",
  supportingDocument = "supportingDocument",
  fixedAsset = "fixedAsset",
  task = "task",
  beneficiary = "beneficiary"
}

/**
 * `JournalEntryReference` — A reference to an object, expressed as a combination of type and id.
 */
export interface JournalEntryReference {
  /**
   * The type of the referred object.
   *
   * Some journal entry lines might reference an object.
   * This field indicates the type of the object.
   */
  type: TypeReference;
  /**
   * The ID of the refered object.
   *
   * Some journal entry lines might reference an object.
   * This fields indicates the ID of the object.
   */
  referredId: string;
}

export type JournalEntryReferenceDocument = JournalEntryReference & Document<string>;

export const journalEntryReferenceSchema = new Schema<JournalEntryReferenceDocument>({
  _id: { type: String, default: () => ulid() },
  type: {
    type: String,
    required: true,
    enum: Object.values(TypeReference), // Mongoose doesn't support Typescript ENUM
  },
  referredId: { type: String, required: true },
});

export enum Direction {
  debit = "debit",
  credit = "credit",
}

/* Defining a type that is a union of all the keys of the AnomalyParameters interface. */
export enum AnomalyError {
  // References type
  referenceTypeBankAccountRequired = "reference-type-bank-account-required",
  referenceTypeRealEstateAssetRequired = "reference-type-real-estate-asset-required",
  referenceTypeRentalUnitRequired = "reference-type-rental-unit-required",
  referenceTypeRentalAgreementRequired = "reference-type-rental-agreement-required",
  referenceTypePartnerRequired = "reference-type-partner-required",
  referenceTypeRealEstateLoanRequired = "reference-type-real-estate-loan-required",
  referenceTypeRentalUnitNotMatchRealEstateAsset = "reference-type-rental-unit-not-match-real-estate-asset",
  referenceTypeRentalAgreementNotMatchRentalUnit = "reference-type-rental-agreement-not-match-rental-unit",
  referenceTypeSupportingDocumentRequired = "reference-type-supporting-document-required",

  // Object id link
  objectIdLinkRentalUnitRequired = "object-id-link-rental-unit-required",

  // TVA
  taxTvaEnableRequired = "tax-tva-enable-required",
  taxTvaLedgerAccount445720Required = "tax-tva-ledger-account-445720-required",
  taxTvaPositiveRequired = "tax-tva-positive-required",

  // Loan
  loanInsuranceEnableRequired = "loan-insurance-enable-required",
  loanLedgerAccount616600Required = "loan-ledger-account-616600-required",
  loanLedgerAccount661110Required = "loan-ledger-account-661110-required",
  loanRepaymentDeadlineAmountEqualTransactionAmountRequired = "loan-repayment-deadline-amount-equal-transaction-amount-required",
  loanRepaymentDeadlineDateEqualTransactionDateRequired = "loan-repayment-deadline-date-equal-transaction-date-required",

  // Asset
  realEstateAssetNotSameAcquisitionAmount = "real-estate-asset-not-same-acquisition-amount",
  realEstateAssetNotSameBoughtFeeAmount = "real-estate-asset-not-same-bought-fee-amount",
}

export enum AnomalyCode {
  // References type
  referenceType = "reference-type",

  // Object id link
  objectIdLink = "object-id-link",

  // TVA
  taxTva = "tax-tva",

  // Loan
  loan = "loan",

  // Asset
  asset = "asset",
}

export interface AnomalyParameters {
  [AnomalyCode.referenceType]: {
    [TypeReference.rentalUnit]: { deleted: boolean };
  };
  [AnomalyCode.objectIdLink]: { rentalUnitDeleted: boolean };
  [AnomalyCode.taxTva]: { taxTvaEnable: boolean };
  [AnomalyCode.loan]: { insuranceEnable: boolean };
  [AnomalyCode.asset]: Record<string, never>;
}

export type AnomalyOptions = {
  [Code in AnomalyCode]?: {
    params?: AnomalyParameters[Code];
    referenceId?: string;
  };
};

export type AnomalyErrorResult = {
  anomalyError: AnomalyError;
  fromReferenceType: TypeReference | "transaction";
  fromReferenceId: string;
  toReferenceType?: TypeReference | "transaction";
  toReferenceId?: string;
};

export type AnomalyErrorResultDocument = AnomalyErrorResult & Document<string>;

const anomalyErrorResultSchema = new Schema<AnomalyErrorResultDocument>({
  _id: { type: String, default: () => ulid() },
  anomalyError: { type: String, require: true, enum: Object.values(AnomalyError) },
  fromReferenceType: { type: String, require: true, enum: [...Object.values(TypeReference), "transaction"] },
  fromReferenceId: { type: String, require: true },
  toReferenceType: { type: String, enum: [...Object.values(TypeReference), "transaction"] },
  toReferenceId: { type: String },
});

export interface AnomaliesChecked {
  anomaliesErrorsSortByPriority: AnomalyErrorResult[];
  operationsErrors: JournalComposedEntry[];
  anomaliesResolvedSortByPriority: AnomalyErrorResult[];
  operationsResolved: JournalComposedEntry[];
  operationsWithErrorsAndResolved: JournalComposedEntry[];
}

export const anomaliesPriority: AnomalyError[] = [
  // Priority 0
  AnomalyError.objectIdLinkRentalUnitRequired,

  // Priority 1
  AnomalyError.referenceTypeBankAccountRequired,
  AnomalyError.referenceTypeRealEstateAssetRequired,
  AnomalyError.referenceTypeRentalUnitRequired,
  AnomalyError.referenceTypeRentalAgreementRequired,
  AnomalyError.referenceTypePartnerRequired,
  AnomalyError.referenceTypeRealEstateLoanRequired,
  AnomalyError.referenceTypeSupportingDocumentRequired,

  // Priority 2
  AnomalyError.taxTvaEnableRequired,
  AnomalyError.taxTvaLedgerAccount445720Required,
  AnomalyError.loanInsuranceEnableRequired,
  AnomalyError.loanLedgerAccount616600Required,
  AnomalyError.loanLedgerAccount661110Required,

  // Priority 3
  AnomalyError.taxTvaPositiveRequired,
  AnomalyError.loanRepaymentDeadlineAmountEqualTransactionAmountRequired,
  AnomalyError.loanRepaymentDeadlineDateEqualTransactionDateRequired,
];

export const anomaliesSortByPriority = (anomaliesErrors: AnomalyErrorResult[]): AnomalyErrorResult[] =>
  anomaliesErrors.sort((a, b) => {
    return anomaliesPriority.indexOf(a.anomalyError) - anomaliesPriority.indexOf(b.anomalyError);
  });

/**
 * `JournalEntryLine` — An individual line in a journal entry
 */
export interface JournalEntryLine {
  _id?: string;
  id?: string;
  direction: Direction;
  account: LedgerAccountNumber;
  amount: number;
  accountName: LedgerAccountName;
  refs?: JournalEntryReference[]; // References for this line
  anomalies?: AnomalyErrorResult[];
  date?: string; // FOR IS and FEC - Not store but for accounting Restitution (TEMP: because after we could create AccountingLine)
}

export type JournalEntryLineDocument = JournalEntryLine & Document<string>;

// A VOIR COMMENT RAJOUTER LE TYPE sur le document ca fait un conflit entre DECIMAL et number ?
const journalEntryLineSchema = new Schema<JournalEntryLineDocument>(
  {
    _id: { type: String, default: () => ulid() },
    direction: { type: String, required: true, enum: Object.values(Direction) },
    account: { type: String, required: true },
    amount: { type: Schema.Types.Decimal128, required: true },
    accountName: String,
    refs: [journalEntryReferenceSchema],
    anomalies: [anomalyErrorResultSchema],
  },
  {
    toJSON: {
      versionKey: false,
      transform(doc, ret: JournalEntryLineDocument) {
        ret.id = ret._id;
        delete ret._id;
        decimal2JSON(ret);
        return ret;
      },
    },
    toObject: {
      versionKey: false,
      transform(doc, ret: JournalEntryLine) {
        ret._id = ret.id;
        delete ret.id;
        return ret;
      },
    },
  }
);

/**
 * `JournalEntry` — An operation in the general ledger
 */
export interface JournalEntry {
  id?: JournalEntryId;
  date: IsoDate;
  description?: string;
  lines?: JournalEntryLine[];
  suggestedLines?: JournalEntryLine[];
}

export type JournalEntryDocument = JournalEntry & Document<string>;

export const journalEntrySchema = new Schema<JournalEntryDocument>(
  {
    _id: { type: String, default: () => ulid() },
    date: String,
    description: String,
    lines: [journalEntryLineSchema],
    suggestedLines: [journalEntryLineSchema],
  },
  {
    toJSON: {
      versionKey: false,
      transform(doc, ret: JournalEntryDocument) {
        ret.id = ret._id;
        decimal2JSON(ret);
        delete ret._id;
        return ret;
      },
    },
  }
);

/**
 * `JournalComposedEntry` — A set of related operations in the general ledger
 * If present, the journal composed entry linked with this transaction.
 *
 * When this component is not present, the transaction has not been categorized yet.
 */
export type JournalComposedEntry = {
  id: string; // Today, Id is equivalent to transactionId
  transactionId: string; // If tomorrow we want a proper ID for JournalComposedEntry - we add here TransactionId for compatibility
  productId: string;
  accountingPeriodId: AccountingPeriodId;
  // List of operation in the general ledger
  journalEntry: JournalEntry;
  // User-submitted classification (empty if this is a suggestion !)
  original?: CategorizationEntry[];
  // Optional user-submitted textual description of the operation
  summary?: string;
  // Virtual (expand=xx)
  expand?: {
    activity?: ActivitiesModel.Activity;
    transaction?: Omit<Transaction, "operations">;
  };
  createdAt: string;
  updatedAt: string;
};
export type JournalComposedEntryPatch = Required<Pick<JournalComposedEntry, "id" | "summary">>;

const journalComposedEntrySchema = new Schema<JournalComposedEntryDocument>(
  {
    _id: { type: String, default: () => ulid() },
    transactionId: { type: String, required: true, index: true, sparse: true, unique: true },
    productId: { type: String, required: true, index: true },
    accountingPeriodId: { type: String, required: true, index: true },
    journalEntry: journalEntrySchema,
    original: [categorizationEntrySchema],
    summary: String,
  },
  {
    timestamps: true,
    toJSON: {
      versionKey: false,
      virtuals: true,
      transform(doc, ret: JournalComposedEntryDocument) {
        ret.id = ret._id;
        decimal2JSON(ret);
        delete ret._id;
        return ret;
      },
    },
  }
);

journalComposedEntrySchema.virtual("expand.transaction", {
  ref: TransactionModelSpec.NAME, // Name of Transaction Model
  localField: "transactionId",
  foreignField: "_id",
  justOne: true,
});

journalComposedEntrySchema.virtual("expand.product", {
  ref: "Products", // Name of Product Model
  localField: "productId",
  foreignField: "_id",
  justOne: true,
});

export type JournalComposedEntryDocument = JournalComposedEntry & Document<string>;

// Used in RepositoryMixinFactory
export const JournalComposedEntryModel = model<JournalComposedEntryDocument>(
  OperationModelSpec.NAME,
  journalComposedEntrySchema,
  OperationModelSpec.COLLECTION
);

// Criteria to search a suggestion
export interface SuggestionCriteria {
  date: IsoDate;
  summary: string;
  amount: number;
}

// API
export namespace OperationsService {
  export type CreateIn = Pick<JournalComposedEntry, "productId" | "accountingPeriodId" | "transactionId"> & {
    entries: CategorizationEntry[];
  };
  export type CreateOut = JournalComposedEntry;

  export type PatchIn = JournalComposedEntryPatch;
  export type PatchOut = JournalComposedEntry;

  export type ListCategoriesIn = Pick<JournalComposedEntry, "accountingPeriodId">;
  export type ListCategoriesOut = { number: string; name: string | undefined }[];
}
