









































































































































import EventsGeneralAssemblyEndModal from "@/components/core/events/generalAssembly/modals/EventsGeneralAssemblyEndModal.vue";
import EventsGeneralAssemblyPVModal from "@/components/core/events/generalAssembly/modals/EventsGeneralAssemblyPVModal.vue";
import EventsYearEndModal from "@/components/core/events/yearEnd/EventsYearEndModal.vue";
import TaskGroup from "@/components/core/tasks/group/TaskGroup.vue";
import { TaskGroupLocal, TaskLocal } from "@/models";
import router from "@/router";
import { ROUTE_NAMES } from "@/router/routes";
import {
  productsStore,
  coreStore,
  documentsStore,
  assembliesStore,
  partnersStore,
  tasksStore,
} from "@/store";
import { FeedbackTypeEnum } from "@/store/modules/Core.store";
import { downloadFileFromUrl } from "@/utils";
import { GeneralAssemblyEvent, TaskStatus, TaskCode } from "@edmp/api";
import {
  computed,
  ComputedRef,
  defineComponent,
  PropType,
  ref,
  Ref,
} from "@vue/composition-api";
import moment from "moment";
import EventsGeneralAssemblyModal from "../modals/EventsGeneralAssemblyModal.vue";

export default defineComponent({
  name: "EventsGeneralAssemblyInfos",
  components: {
    TaskGroup,
    EventsGeneralAssemblyPVModal,
    EventsGeneralAssemblyModal,
    EventsGeneralAssemblyEndModal,
    EventsYearEndModal,
  },
  props: {
    event: {
      type: Object as PropType<GeneralAssemblyEvent>,
      required: true,
    },
    taskGroup: {
      type: Object as PropType<TaskGroupLocal>,
      required: true,
    },
    openAgPVModal: { type: String as PropType<"true"> },
  },
  setup(props) {
    const showGeneralAssemblyModal: Ref<boolean> = ref(false);
    const isOpenAgPVModal = ref(Boolean(props.openAgPVModal));
    const endModal: Ref<boolean> = ref(false);

    const name: ComputedRef<string> = computed(() => {
      if (props.event.type == "ordinary") {
        if (props.event.pattern == "yearEnd")
          return "Approbation des comptes annuelle";
        else return "Assemblée Générale Ordinaire";
      } else {
        return "Assemblée Générale Extraordinaire";
      }
    });

    const dateText: ComputedRef<string> = computed(() => {
      return props.event.startAt
        ? moment(props.event.startAt).format("DD/MM/YYYY")
        : "";
    });

    const hourText: ComputedRef<string> = computed(() => {
      return props.event.startAt
        ? moment(props.event.startAt).format("HH:mm")
        : "";
    });

    const partnersList = computed(() => partnersStore.partners);

    const resolveTask = async (task: TaskLocal) => {
      if ([TaskCode.Invite, TaskCode.InviteYearEnd].includes(task.code)) {
        const generalAssemblyEvent = {
          ...props.event,
          invitation: partnersList.value.map((p) => p.id),
        };
        const assemblyUpdated = await assembliesStore.invite(
          generalAssemblyEvent
        );

        await documentsStore.fetchDocuments(productsStore.currentId);
        tasksStore.fetchTaskGroups({});
        coreStore.displayFeedback({
          type: FeedbackTypeEnum.FEEDBACK,
          message: `Un nouveau document a été généré par Ownily et est disponible dans l'espace Mes documents ou dans la section document du détail de votre Assemblée Générale`,
          timeout: 8000,
        });

        const document = assemblyUpdated.document;
        if (document?.href) {
          downloadFileFromUrl(document.href, document.name ?? "Convocations");
        }
      } else if (
        [TaskCode.Report, TaskCode.ReportYearEnd].includes(task.code)
      ) {
        isOpenAgPVModal.value = true;
      }
    };

    const readonly: ComputedRef<boolean> = computed(() => {
      return (
        props.taskGroup.tasksLocal.findIndex(
          (task) =>
            [TaskCode.Invite, TaskCode.InviteYearEnd].includes(task.code) &&
            task.status === TaskStatus.COMPLETED
        ) >= 0
      );
    });

    function displayEndModal() {
      isOpenAgPVModal.value = false;
      endModal.value = true;
    }
    const closeEndModal = () => {
      endModal.value = false;
      if (props.event.pattern == "yearEnd") {
        router.push({ name: ROUTE_NAMES.EventsYearEnd });
      }
    };

    // const showYearEndModal = computed(() => taskStore.showYearEndModal);
    // const closeYearEndModal = () => {
    //   taskStore.closeYearEndModal();
    // };

    return {
      name,
      dateText,
      hourText,
      resolveTask,
      partnersList,
      isOpenAgPVModal,
      showGeneralAssemblyModal,
      readonly,
      endModal,
      displayEndModal,
      closeEndModal,
      TaskStatus,
    };
  },
});
