




















































































import { StripeCheckout } from "@vue-stripe/vue-stripe";

import {
  computed,
  defineComponent,
  onBeforeMount,
  PropType,
  ref,
  Ref,
  watch,
} from "@vue/composition-api";
import { SubscriptionsModel, TaskCode } from "@edmp/api";
import {
  accountingPeriodsStore,
  productsStore,
  realEstateAssetsStore,
  subscriptionsStore,
  tasksStore,
} from "@/store";
import { useSubscription } from "../subscription.usable";
import Payment from "./Payment.vue";
import router from "@/router";
import { ROUTE_NAMES } from "@/router/routes";

export interface VSubscriptionStripe {
  /**
   * A function that is called when the user clicks on the "Pay" button. It is used to redirect the user to the Stripe payment page.
   * @use This is called outside of this component
   * @example
   * (context.refs.subscriptionStripe as unknown as VSubscriptionStripe).redirectToCheckout(sessionCheckoutId);
   */
  redirectToCheckout: (sessionId: string) => void;

  /**
   * It checks if the subscription has a sessionCheckoutId, if it does, it sets the sessionId to the
   * sessionCheckoutId and redirects to the Stripe Checkout, if it doesn't, it redirects to the Stripe Portal
   * @use This is called outside of this component
   * @example
   * (context.refs.subscriptionStripe as unknown as VSubscriptionStripe).retryPayment();
   */
  retryPayment: () => void;
}

export default defineComponent({
  name: "SubscriptionStripe",
  components: {
    Payment,
    StripeCheckout,
  },
  props: {
    subscriptionId: { type: String, required: true },
    stripeReturnStatus: {
      type: String as PropType<
        | SubscriptionsModel.PaymentStatusParams.succeeded
        | SubscriptionsModel.PaymentStatusParams.canceled
        | "portal"
      >,
      required: false,
    },
  },
  setup(props, context) {
    const openModal: Ref<boolean> = ref(false);
    let useSub = useSubscription(props.subscriptionId);
    const subscription = computed(() =>
      subscriptionsStore.getSubscription(props.subscriptionId)
    );

    /**
     * Actions
     */
    const closePaymentReturn = () => {
      openModal.value = false;
      const task = tasksStore.userNotificationsTasks.find(
        (task) =>
          task.code ===
          (props.stripeReturnStatus === "succeeded"
            ? TaskCode.SubscriptionActive
            : props.stripeReturnStatus === "canceled"
            ? TaskCode.SubscriptionError
            : undefined)
      );
      if (task) {
        tasksStore.validateTask({
          taskLocal: task,
        });
      }
      if (
        accountingPeriodsStore.isLMNP &&
        props.stripeReturnStatus === "succeeded" &&
        realEstateAssetsStore.realEstateAssets.length === 0
      ) {
        context.root.$router.push({
          name: ROUTE_NAMES.RealEstateAssets,
        });
      } else {
        useSub.goDetail();
      }
    };

    const redirectToCheckout = (sessionId: string) => {
      useSub.stripeStates.sessionId = sessionId; // You will be redirected to Stripe's secure checkout page
      (context.refs.stripeCheckout as StripeCheckout).redirectToCheckout();
    };

    const retryPayment = () => {
      useSub.stripeStates.subscriptionPaymentRetryIsLoading = true;
      if (subscription.value?.payment.sessionCheckoutId) {
        useSub.stripeStates.sessionId =
          subscription.value.payment.sessionCheckoutId;
        redirectToCheckout(subscription.value.payment.sessionCheckoutId);
      } else {
        useSub.goStripePortal();
      }
      useSub.stripeStates.subscriptionPaymentRetryIsLoading = false;
    };
    const init = async () => {
      if (props.stripeReturnStatus) {
        if (props.stripeReturnStatus !== "portal") {
          openModal.value = true;
        }
      } else {
        openModal.value = false;
      }
    };
    const switchSub = async () => {
      const newSubId = await subscriptionsStore.getSubscriptionByProduct(
        productsStore.currentId
      )?.id;
      if (
        newSubId &&
        openModal.value === false &&
        (router.currentRoute.name === ROUTE_NAMES.SubscriptionDetails ||
          router.currentRoute.name === ROUTE_NAMES.SubscriptionDetailsContinue)
      ) {
        await context.root.$router.push({
          name: ROUTE_NAMES.SubscriptionDetails,
          params: {
            subscriptionId: newSubId,
          },
        });
      }
    };
    //watch triggered when we are switching between products to change subscriptionId prop
    watch(
      () => [
        subscriptionsStore.getSubscriptionByProduct(productsStore.currentId),
      ],
      async () => await switchSub(),
      { deep: true }
    );

    watch(
      () => [props.subscriptionId],
      async () => {
        openModal.value = false;
        await (useSub = useSubscription(props.subscriptionId));
        init();
      },
      { deep: true }
    );

    //watch triggered if there is a stripeReturnStatus to open the modal
    watch(
      () => [props.stripeReturnStatus],
      () => init(),
      { deep: true }
    );

    onBeforeMount(async () => await init());

    /**
     * Return data
     */
    return {
      useSub,
      subscription,
      PaymentStatusParams: SubscriptionsModel.PaymentStatusParams,
      PaymentStatus: SubscriptionsModel.PaymentStatus,
      openModal,
      closePaymentReturn,
      redirectToCheckout,
      retryPayment,
    };
  },
});
