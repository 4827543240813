






import { TaskLocal } from "@/models";
import { accountingBalanceSheetsStore } from "@/store";
import { tasksNeedingBalanceSheetFetch } from "@/utils";
import { OmitField, TasksService } from "@edmp/api";
import { defineComponent, onBeforeMount, PropType } from "@vue/composition-api";
import Task from "./Task.vue";

export default defineComponent({
  name: "TaskCard",
  components: {
    Task,
  },
  props: {
    taskLocal: {
      type: Object as PropType<TaskLocal>,
      required: true,
    },
    resolveTask: {
      type: Function as PropType<
        (
          task: TaskLocal,
          data?: OmitField<TasksService.ValidateIn, "id">
        ) => Promise<void>
      >,
      required: false,
    },
  },
  setup(props) {
    onBeforeMount(async () => {
      if (tasksNeedingBalanceSheetFetch.includes(props.taskLocal.code)) {
        await accountingBalanceSheetsStore.fetchAccountingBalanceSheets();
      }
    });
    return {};
  },
});
