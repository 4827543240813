import { TaskGroupLocal, TaskLocal } from "@/models";
import { tasksService } from "@/services/Tasks.service";
import store from "@/store/root";
import { transformGroupsLocal, transformLocal } from "@/utils";
import {
  OmitField,
  SubscriptionsModel,
  TasksService,
  TaskStatus,
  TaskTypeReference,
  TaskCode,
  TaskGroupCode,
  TaxRegime,
  getMoment,
  getReferredIdByTypeWithReferences,
} from "@edmp/api";
import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";
import {
  accountingPeriodsStore,
  activitiesStore,
  productsStore,
  subscriptionsStore,
} from "..";

export interface TasksState {
  taskGroups: Array<TaskGroupLocal>;
  loading: boolean;
  showYearEndModal: boolean;
}

@Module({
  name: "tasks-store",
  dynamic: true,
  namespaced: true,
  store,
})
export class TasksStore extends VuexModule implements TasksState {
  taskGroups: Array<TaskGroupLocal> = [];
  loading = false;
  showYearEndModal = false;

  get currentAccountingPeriodTaskGroups(): TaskGroupLocal[] {
    return this.taskGroups
      .filter(
        (taskGroup) =>
          getReferredIdByTypeWithReferences(
            taskGroup.references,
            TaskTypeReference.product
          ) === productsStore.currentId &&
          getReferredIdByTypeWithReferences(
            taskGroup.references,
            TaskTypeReference.accountingPeriod
          ) === accountingPeriodsStore.currentId
      )
      .map((taskGroup) => {
        const filteredTasks = taskGroup.tasksLocal.filter(
          (task) =>
            task.code !== TaskCode.Create &&
            task.code !== TaskCode.UploadCerfa2072
        );
        return {
          ...taskGroup,
          tasksLocal: filteredTasks,
        };
      })
      .filter((taskGroup) => taskGroup.tasksLocal.length > 0);
  }

  get openAccountingPeriodTaskGroups(): TaskGroupLocal[] {
    const openAccountingPeriodIds = accountingPeriodsStore.accountingPeriods
      .filter((accountingPeriod) => {
        if (
          !accountingPeriod.closed &&
          getMoment(accountingPeriod.endAt).isBefore(getMoment())
        ) {
          return accountingPeriod;
        }
      })
      .sort((acc1, acc2) =>
        getMoment(acc1.startAt).isBefore(getMoment(acc2.startAt)) ? -1 : 1
      )
      .map((accountingPeriod) => accountingPeriod.id);

    return this.taskGroups
      .filter(
        (taskGroup) =>
          getReferredIdByTypeWithReferences(
            taskGroup.references,
            TaskTypeReference.product
          ) === productsStore.currentId
      )
      .map((taskGroup) => {
        const filteredTasks = taskGroup.tasksLocal
          .filter((task) =>
            task.references?.some(
              (reference) =>
                reference.type === TaskTypeReference.accountingPeriod &&
                openAccountingPeriodIds.includes(reference.referredId)
            )
          )
          .filter((task) =>
            subscriptionsStore.getCurrentSubscription?.plan.type !==
            SubscriptionsModel.PlanType.Premium
              ? task.code !== TaskCode.BalanceSimple
              : true
          )
          .filter(
            (task) =>
              task.code !== TaskCode.Create &&
              task.code !== TaskCode.UploadCerfa2072
          );
        return {
          ...taskGroup,
          tasksLocal: filteredTasks,
        };
      })
      .filter((taskGroup) => taskGroup.tasksLocal.length > 0);
  }

  get accountingPeriodTaskGroup(): TaskGroupLocal | undefined {
    return this.taskGroups.find(
      (taskGroup) =>
        taskGroup.groupCode === TaskGroupCode.AccountingPeriod &&
        getReferredIdByTypeWithReferences(
          taskGroup.references,
          TaskTypeReference.product
        ) === productsStore.currentId &&
        getReferredIdByTypeWithReferences(
          taskGroup.references,
          TaskTypeReference.accountingPeriod
        ) === accountingPeriodsStore.currentId
    );
  }
  get startWellTaskGroup(): TaskGroupLocal[] {
    const taxRegime =
      accountingPeriodsStore.currentAccountingPeriod?.taxRegime ??
      TaxRegime.IR_2072;
    switch (taxRegime) {
      case TaxRegime.IR_2072:
        return this.taskGroups.filter(
          (taskGroup) =>
            taskGroup.groupCode === TaskGroupCode.StartWell &&
            getReferredIdByTypeWithReferences(
              taskGroup.references,
              TaskTypeReference.product
            ) === productsStore.currentId
        );
      case TaxRegime.IS_2065:
        return this.taskGroups.filter((taskGroup) => {
          const accountingPeriodId = getReferredIdByTypeWithReferences(
            taskGroup.references,
            TaskTypeReference.accountingPeriod
          );
          return (
            (taskGroup.groupCode === TaskGroupCode.StartWellInformation ||
              taskGroup.groupCode === TaskGroupCode.StartWellAccounting) &&
            getReferredIdByTypeWithReferences(
              taskGroup.references,
              TaskTypeReference.product
            ) === productsStore.currentId &&
            (!accountingPeriodId ||
              accountingPeriodId === accountingPeriodsStore.currentId)
          );
        });
      case TaxRegime.LMNP_2031:
        return this.taskGroups.filter((taskGroup) => {
          const accountingPeriodId = getReferredIdByTypeWithReferences(
            taskGroup.references,
            TaskTypeReference.accountingPeriod
          );
          const activityId = getReferredIdByTypeWithReferences(
            taskGroup.references,
            TaskTypeReference.activity
          );
          return (
            (taskGroup.groupCode === TaskGroupCode.StartWellLMNP ||
              taskGroup.groupCode === TaskGroupCode.StartWellAccountingLMNP) &&
            getReferredIdByTypeWithReferences(
              taskGroup.references,
              TaskTypeReference.product
            ) === productsStore.currentId &&
            (!accountingPeriodId ||
              accountingPeriodId === accountingPeriodsStore.currentId) &&
            (!activityId || activityId === activitiesStore.currentId)
          );
        });
      default:
        return [];
    }
  }

  get closureTaskGroup(): TaskGroupLocal[] {
    const taxRegime =
      accountingPeriodsStore.currentAccountingPeriod?.taxRegime ??
      TaxRegime.IR_2072;
    switch (taxRegime) {
      case TaxRegime.IR_2072:
        return this.currentAccountingPeriodTaskGroups.filter((taskGroup) =>
          [
            TaskGroupCode.TransactionsYearEnd,
            TaskGroupCode.TaxReturnPrepareClose,
            TaskGroupCode.EventGeneralAssemblyYearEnd,
            TaskGroupCode.TeletransmitTaxReturn,
            TaskGroupCode.BalanceSheet,
          ].includes(taskGroup.groupCode)
        );

      case TaxRegime.IS_2065:
        return this.currentAccountingPeriodTaskGroups.filter((taskGroup) =>
          [
            TaskGroupCode.YearEndTransactionsCheck,
            TaskGroupCode.FinancialReporting,
            TaskGroupCode.GetTaxReturns,
            TaskGroupCode.EventGeneralAssemblyYearEnd,
            TaskGroupCode.TeletransmitTaxReturn,
          ].includes(taskGroup.groupCode)
        );

      case TaxRegime.LMNP_2031:
        return this.currentAccountingPeriodTaskGroups.filter((taskGroup) =>
          [
            TaskGroupCode.YearEndTransactionsCheck,
            TaskGroupCode.FinancialReporting,
            TaskGroupCode.GetTaxReturns,
            TaskGroupCode.TeletransmitTaxReturn,
          ].includes(taskGroup.groupCode)
        );
      default:
        return [];
    }
  }

  get closureCustomTaskGroup(): TaskGroupLocal[] {
    const taxRegime =
      accountingPeriodsStore.currentAccountingPeriod?.taxRegime ??
      TaxRegime.IR_2072;
    switch (taxRegime) {
      case TaxRegime.IR_2072:
        return this.openAccountingPeriodTaskGroups.filter(
          (taskGroup) =>
            taskGroup.groupCode === TaskGroupCode.TransactionsYearEnd ||
            taskGroup.groupCode === TaskGroupCode.TaxReturnPrepareClose ||
            taskGroup.groupCode === TaskGroupCode.EventGeneralAssemblyYearEnd
        );
      default:
        return [];
    }
  }

  get eventAccountingPeriodClosureTaskGroup(): TaskGroupLocal[] {
    const taxRegime =
      accountingPeriodsStore.currentAccountingPeriod?.taxRegime ??
      TaxRegime.IR_2072;
    switch (taxRegime) {
      case TaxRegime.IR_2072:
        return this.currentAccountingPeriodTaskGroups.filter(
          (taskGroup) =>
            taskGroup.groupCode === TaskGroupCode.EventAccountingPeriodClosure
        );
      default:
        return [];
    }
  }

  get assemblyTaskGroup(): TaskGroupLocal[] {
    const taxRegime =
      accountingPeriodsStore.currentAccountingPeriod?.taxRegime ??
      TaxRegime.IR_2072;
    switch (taxRegime) {
      case TaxRegime.IR_2072:
        return this.currentAccountingPeriodTaskGroups.filter(
          (taskGroup) =>
            taskGroup.groupCode === TaskGroupCode.EventGeneralAssemblyYearEnd ||
            taskGroup.groupCode === TaskGroupCode.EventGeneralAssembly
        );
      case TaxRegime.IS_2065:
        return this.currentAccountingPeriodTaskGroups.filter(
          (taskGroup) =>
            taskGroup.groupCode === TaskGroupCode.EventGeneralAssemblyYearEnd ||
            taskGroup.groupCode === TaskGroupCode.EventGeneralAssembly
        );
      default:
        return [];
    }
  }

  get completedTasks(): TaskLocal[] {
    return this.taskGroups.flatMap((taskGroup) =>
      taskGroup.tasksLocal.filter(
        (task) => task.status === TaskStatus.COMPLETED
      )
    );
  }

  get notCompletedTasks(): TaskLocal[] {
    return this.taskGroups.flatMap((taskGroup) =>
      taskGroup.tasksLocal.filter(
        (task) => task.status !== TaskStatus.COMPLETED
      )
    );
  }

  get notCompletedTaskGroupWithDueDate(): TaskGroupLocal[] {
    const taskGroupsWithDueDate = this.currentAccountingPeriodTaskGroups.filter(
      (taskGroup) => {
        const hasDueDate = taskGroup.tasksLocal.some(
          (taskLocal) => taskLocal.dueDate
        );

        if (hasDueDate) {
          const firstDueDate = taskGroup.tasksLocal.filter(
            (taskLocal) => taskLocal.dueDate
          )[0];

          taskGroup.dueDate = firstDueDate.dueDate;
        }

        return hasDueDate;
      }
    );
    return taskGroupsWithDueDate.filter(
      (taskGroup) => taskGroup.status !== TaskStatus.COMPLETED
    );
  }

  get notCompletedTaskGroupWithoutDueDate(): TaskGroupLocal[] {
    const taskGroups: TaskGroupLocal[] =
      this.currentAccountingPeriodTaskGroups.filter((taskGroup) => {
        taskGroup.tasksLocal.some((taskLocal) => !taskLocal.dueDate);
      });
    return taskGroups.filter(
      (taskGroup) => taskGroup.status !== TaskStatus.COMPLETED
    );
  }

  get userNotificationsTasks(): TaskLocal[] {
    return (
      this.taskGroups
        .find(
          (taskGroup) => taskGroup.groupCode === TaskGroupCode.UserNotifications
        )
        ?.tasksLocal.filter((task) => {
          if (task.status !== TaskStatus.PENDING) {
            return false;
          }
          if (task.code === TaskCode.EmailValidate) {
            return !!getReferredIdByTypeWithReferences(
              task.references,
              TaskTypeReference.user
            );
          }
          return true;
        }) || []
    );
  }

  get isCurrentAccountingPeriodFillCerfa2072Completed(): boolean {
    return !!this.completedTasks.find(
      (task) =>
        task.code === TaskCode.FillCerfa2072 &&
        getReferredIdByTypeWithReferences(
          task.references,
          TaskTypeReference.accountingPeriod
        ) === accountingPeriodsStore.currentId
    );
  }

  @Mutation
  reset(): void {
    this.taskGroups = [];
    this.loading = false;
  }

  @Mutation
  setTaskGroups(taskGroups: TaskGroupLocal[]): void {
    this.taskGroups = taskGroups;
  }

  @Mutation updateTask(task: TaskLocal): void {
    const taskGroupToMutateIndex: number = this.taskGroups.findIndex(
      (group) =>
        group.groupCode === task.groupCode &&
        !!group.tasksLocal.find((t) => t.id === task.id)
    );
    const taskToMutateIndex: number = this.taskGroups[
      taskGroupToMutateIndex
    ].tasksLocal.findIndex((t) => t.id === task.id);
    this.taskGroups[taskGroupToMutateIndex].tasksLocal[
      taskToMutateIndex
    ].status = task.status;
  }

  @Mutation
  setLoading(isLoading: boolean): void {
    this.loading = isLoading;
  }

  @Mutation
  setYearEndModal(isClosing: boolean): void {
    this.showYearEndModal = isClosing;
  }

  @Action
  closeYearEndModal(): void {
    this.setYearEndModal(false);
  }

  @Action
  openYearEndModal(): void {
    const closureTaskGroups: TaskGroupLocal[] = this.closureTaskGroup;
    const isYearEndClosed =
      !!closureTaskGroups.length &&
      closureTaskGroups.every(
        (taskGroup) => taskGroup.status === TaskStatus.COMPLETED
      );

    if (isYearEndClosed) {
      this.setYearEndModal(true);
    }
  }

  @Action
  async fetchTaskGroups(params: TasksService.ListIn): Promise<void> {
    this.setLoading(true);
    try {
      this.setTaskGroups(transformGroupsLocal(await tasksService.list(params)));
    } catch (err) {
      // manage error
    }
    this.setLoading(false);
  }

  @Action
  async validateTask(params: {
    taskLocal: TaskLocal;
    data?: OmitField<TasksService.ValidateIn, "id">;
  }): Promise<void> {
    const taskUpdated: TaskLocal | undefined = transformLocal(
      await tasksService.validate({
        id: params.taskLocal.id,
        ...params.data,
      })
    );
    if (taskUpdated) {
      const taskLocalUpdated = {
        ...params.taskLocal,
        ...taskUpdated,
      };
      this.updateTask(taskLocalUpdated);
    }
  }

  @Action
  async activateTask(taskLocal: TaskLocal): Promise<void> {
    const taskUpdated: TaskLocal | undefined = transformLocal(
      await tasksService.activate({
        id: taskLocal.id,
      })
    );
    if (taskUpdated) {
      taskLocal = { ...taskLocal, ...taskUpdated };
      this.updateTask(taskLocal);
    }
  }
}
