import store from "@/store/root";
import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";
import { permissionsService, productsService } from "@/services";
import { OneOf, ProductsModel, ProductsService } from "@edmp/api";
import Vue from "vue";
import {
  assembliesStore,
  accountingPeriodsStore,
  bankAccountsStore,
  partnersStore,
  realEstateAmortisationsStore,
  realEstateAssetsStore,
  rentalAgreementsStore,
  subscriptionsStore,
  tasksStore,
  documentsStore,
  transactionsStore,
  fixedAssetsStore,
  fixedAssetAmortisationsStore,
  activitiesStore,
  accountingBalanceSheetsStore,
  productsStore,
} from "..";

export interface ProductsState {
  products: ProductsModel.Product[];
  currentId: string;
  loading: boolean;
}

@Module({
  name: "products-store",
  dynamic: true,
  namespaced: true,
  store,
})
export class ProductsStore extends VuexModule implements ProductsState {
  products: ProductsModel.Product[] = [];
  currentId = "";
  loading = false;

  @Mutation
  reset(): void {
    this.products = [];
    this.currentId = "";
    this.loading = false;
  }

  @Mutation
  setLoading(isLoading: boolean) {
    this.loading = isLoading;
  }

  get currentProduct() {
    return this.products.find((c) => c.id === this.currentId);
  }

  @Mutation
  setCurrentId(id: string): void {
    this.currentId = id;
  }

  @Mutation
  setProduct(product: ProductsModel.Product) {
    const index = this.products.findIndex(({ id }) => id == product.id);
    if (index !== -1) {
      Vue.set(this.products, index, product);
    } else {
      this.products.push(product);
    }
  }

  @Action
  async selectFirst() {
    if (this.products.length > 0) {
      await this.switchProduct({ id: this.products[0].id });
    }
  }

  @Action
  async switchProduct(params: { id: string; force?: boolean }) {
    const { id, force = false } = params;
    if (id !== this.currentId || force) {
      this.setCurrentId(id);

      /*
       * Refesh linked stores
       */
      // REST
      activitiesStore.reset();
      accountingBalanceSheetsStore.reset();
      subscriptionsStore.reset();
      tasksStore.reset();
      bankAccountsStore.reset();
      accountingPeriodsStore.reset();
      partnersStore.reset();
      realEstateAssetsStore.reset();
      realEstateAmortisationsStore.reset();
      rentalAgreementsStore.reset();
      assembliesStore.reset();
      documentsStore.reset();
      transactionsStore.reset();
      fixedAssetsStore.reset();
      fixedAssetAmortisationsStore.reset();

      // BASE
      await subscriptionsStore.fetchSubscriptions();
      await tasksStore.fetchTaskGroups({});

      // INTERMEDIATE
      await Promise.all([
        activitiesStore.fetchActivities(id) as unknown as Promise<void>,
        bankAccountsStore.fetchBankAccounts(id),
        accountingPeriodsStore.fetchAccountingPeriods(id),
        partnersStore.fetchPartners(id),
        realEstateAssetsStore.fetchRealEstateAssets(
          id
        ) as unknown as Promise<void>, // fetch rentalUnits, rentalAgreements and selectFirst
        assembliesStore.fetchGeneralAssemblyEvents(id),
        documentsStore.fetchDocuments(id),
        realEstateAmortisationsStore.fetchRealEstateAmortisations(
          id
        ) as unknown as Promise<void>,
        fixedAssetsStore.fetchFixedAssets(id) as unknown as Promise<void>,
        fixedAssetAmortisationsStore.fetchFixedAssetAmortisations(
          id
        ) as unknown as Promise<void>,
      ]);

      // Select first
      await accountingPeriodsStore.selectFirstYearActive();

      if (this.currentProduct) {
        await activitiesStore.switchActivity(this.currentProduct.activity.id);
      }

      await this.updateProductAbilityWithInterval();
      await tasksStore.fetchTaskGroups({});
    }
  }

  @Action
  async updateProductAbilityWithInterval(): Promise<void> {
    const reload = setInterval(async () => {
      await subscriptionsStore.fetchSubscriptions();
      const subscription = subscriptionsStore.subscriptions.find(
        ({ productId }) => productId === this.currentId
      );
      if (subscription) {
        clearInterval(reload);
        permissionsService.updateProductAbility(
          subscription,
          productsStore.currentProduct
        );
      }
    }, 1000);
  }

  @Action
  async updateProductAbility(): Promise<void> {
    await subscriptionsStore.fetchSubscriptions();
    const subscription = subscriptionsStore.subscriptions.find(
      ({ productId }) => productId === this.currentId
    );
    if (subscription) {
      permissionsService.updateProductAbility(
        subscription,
        productsStore.currentProduct
      );
    }
  }

  @Action
  async fetchProducts(params: ProductsService.ListIn) {
    this.setLoading(true);
    const products = await productsService.list(params);
    for (const product of products) {
      this.setProduct(product);
    }
    this.setLoading(false);
    return products;
  }

  @Action
  async fetchProduct(params: ProductsService.GetIn) {
    this.setLoading(true);
    const product = await productsService.get(params);
    this.setProduct(product);
    this.setLoading(false);
    return product;
  }

  get getProduct() {
    return ({
      id: productId,
      activityId,
    }: OneOf<
      Pick<ProductsModel.Product, "id">,
      { activityId: ProductsModel.Product["activity"]["id"] }
    >) => {
      if (productId) {
        return this.products.find(({ id }) => id === productId);
      } else if (activityId) {
        return this.products.find(
          (product) => product.activity.id === activityId
        );
      }
    };
  }

  @Action
  async createProduct(productCreate: ProductsService.CreateIn) {
    this.setLoading(true);
    const productCreated = await productsService.create(productCreate);
    this.setProduct(productCreated);
    await this.fetchProducts({});
    await this.switchProduct({ id: productCreated.id });
    this.setLoading(false);
    return productCreated;
  }

  @Action
  async updateProduct(productUpdate: ProductsService.UpdateIn) {
    this.setLoading(true);
    const productUpdated = await productsService.update(productUpdate);
    this.setProduct(productUpdated);
    this.setLoading(false);
    return productUpdated;
  }

  @Action
  async validateEmail(params: ProductsService.ValidateEmailIn): Promise<void> {
    await productsService.validateEmail(params);
    await this.fetchProducts({});
  }
}
