































import { defineComponent, computed } from "@vue/composition-api";
import { tasksStore } from "@/store";

export default defineComponent({
  name: "Notifications",
  setup() {
    return {
      userNotifications: computed(() => tasksStore.userNotificationsTasks),
    };
  },
});
