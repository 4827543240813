











































































































import UserAccountLogin from "@/components/core/user/UserAccountLogin.vue";
import UserAccountPassword from "@/components/core/user/UserAccountPassword.vue";
import UserInformation from "@/components/core/user/UserInformation.vue";
import { VForm } from "@/models";
import { usersService } from "@/services";
import { coreStore, tasksStore, usersStore } from "@/store";
import { FeedbackTypeEnum } from "@/store/modules/Core.store";
import { User } from "@edmp/api";
import {
  defineComponent,
  onBeforeMount,
  ref,
  Ref,
  watch,
} from "@vue/composition-api";
import Title from "../title/Title.vue";

export default defineComponent({
  name: "User",
  components: {
    Title,
    UserAccountLogin,
    UserAccountPassword,
    UserInformation,
  },
  setup(props, context) {
    const updatedUser: Ref<User | undefined> = ref();
    // Gestion de l'edition / annulation
    const isEditingData: Ref<boolean> = ref(false);

    async function validate(e: Event): Promise<void> {
      e.preventDefault();
      if ((context.refs.form as VForm).validate() && updatedUser.value) {
        try {
          await usersService
            .updateUser({
              id: "me",
              phone: updatedUser.value.phone,
              lastName: updatedUser.value.lastName,
              firstName: updatedUser.value.firstName,
            })
            .then(() => {
              context.emit("validate", updatedUser);
              isEditingData.value = false;
            });
          coreStore.displayFeedback({
            message:
              "La mise à jour de votre profil a bien été prise en compte",
          });
        } catch (err) {
          coreStore.displayFeedback({
            type: FeedbackTypeEnum.ERROR,
            message:
              "Une erreur est survenue lors de la mise à jour de votre profil",
          });
        }
        await tasksStore.fetchTaskGroups({});
        await usersStore.fetchLoggedInUser();
      }
    }

    const init = async () => {
      updatedUser.value = { ...usersStore.loggedInUser };
    };
    async function cancelUpdate() {
      await init();
      isEditingData.value = false;
    }
    watch(
      () => usersStore.loggedInUser,
      () => init(),
      { deep: true }
    );
    onBeforeMount(async () => {
      await init();
    });

    return {
      updatedUser,
      isEditingData,
      validate,
      cancelUpdate,
    };
  },
});
